import React from 'react';

const PlusIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M8.49999 1.30078C8.97864 1.30078 9.36666 1.6888 9.36666 2.16745V7.13411H14.3333C14.812 7.13411 15.2 7.52213 15.2 8.00078C15.2 8.47943 14.812 8.86745 14.3333 8.86745H9.36666V13.8341C9.36666 14.3128 8.97864 14.7008 8.49999 14.7008C8.02134 14.7008 7.63332 14.3128 7.63332 13.8341V8.86745H2.66665C2.18801 8.86745 1.79999 8.47943 1.79999 8.00078C1.79999 7.52213 2.18801 7.13411 2.66665 7.13411H7.63332V2.16745C7.63332 1.6888 8.02134 1.30078 8.49999 1.30078Z"
        fill="#171719"
      />
    </svg>
  );
};

export default PlusIcon;

import { gaMeasurementId } from 'config';
import React from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';

import ChatSection from './sections/ChatSection';
import SideBar from './sections/SideBar';
import SummaryBar from './sections/SummaryBar';

ReactGA.initialize(gaMeasurementId);

const GPTPage: React.FC = () => {
  return (
    <Wrapper>
      <SideBar />
      <ChatSection />
      <SummaryBar />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default GPTPage;

import React from 'react';

const AudioIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        d="M29.115 11.115L21.87 3.87C21.315 3.315 20.55 3 19.755 3H9C7.35 3 6.015 4.35 6.015 6L6 30C6 31.65 7.335 33 8.985 33H27C28.65 33 30 31.65 30 30V13.245C30 12.45 29.685 11.685 29.115 11.115ZM22.5 19.5H19.5V24.915C19.5 26.835 18 28.53 16.08 28.5C13.92 28.47 12.24 26.415 12.885 24.135C13.2 23.055 14.085 22.17 15.18 21.87C16.23 21.585 17.22 21.795 18 22.305V18C18 17.175 18.675 16.5 19.5 16.5H22.5C23.325 16.5 24 17.175 24 18C24 18.825 23.325 19.5 22.5 19.5ZM21 13.5C20.175 13.5 19.5 12.825 19.5 12V5.25L27.75 13.5H21Z"
        fill="#ABABAB"
      />
    </svg>
  );
};

export default AudioIcon;

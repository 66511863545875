import useWindowDimensions from 'hooks/useWindowDimensions';
import AudioIcon from 'icons/Audio';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Text } from '../Text';

const EmptyContent: React.FC = () => {
  const { t } = useTranslation('stt');
  const { isMobile } = useWindowDimensions();
  const titleType = isMobile ? 'heading2' : 'title1';
  const subType = isMobile ? 'label1normal' : 'body1normal';
  return (
    <Container>
      <Text type={titleType}>{t('sttTitle')}</Text>
      <Box>
        <AudioIcon />
        <Text type={subType}>{t('uploadExplanantion')}</Text>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px;
  gap: 60px;
  width: 100%;
  word-wrap: break-word;
`;

const Box = styled.div`
  display: flex;
  padding: var(--Spacing-ml, 20px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 80%;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.lightGrey};
`;

export default EmptyContent;

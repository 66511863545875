import React from 'react';

const SourceIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M13.3201 21.8164H4.32007C3.22007 21.8164 2.32007 20.9164 2.32007 19.8164V3.81641C2.32007 2.71641 3.22007 1.81641 4.32007 1.81641H12.3201L18.3201 7.81641V13.8164H16.3201V8.81641H11.3201V3.81641H4.32007V19.8164H13.3201V21.8164ZM17.3201 21.4764V19.2364L20.2701 22.1864L21.6801 20.7764L18.7301 17.8164H20.9701V15.8164H15.3201V21.4764H17.3201Z"
        fill="#2E2F33"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default SourceIcon;

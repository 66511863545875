import { Tooltip } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { DiarizeRequestDto } from 'api/analyze.api';
import { actions as analyzeAction } from 'features/analyze';
import { selectSessionId, selectSourceLanguage, selectTranslateLanguage } from 'features/analyze/selector';
import { getAllDiarizationsAsync, getDiarizedFileAsync } from 'features/analyze/thunks';
import useStreamSTT from 'hooks/useStreamSTT';
import useWindowDimensions from 'hooks/useWindowDimensions';
import DownloadIcon from 'icons/Download';
import HelpIcon from 'icons/Help';
import RecordIcon from 'icons/Record';
import StopIcon from 'icons/Stop';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { notifyErr, notifySuc } from 'utils/notification';

import { DetailedPlayback } from '../DetailedPlayback';
import { LoadingSpinner } from '../LoadingSpinner';
import { STTHelpDialog } from '../STTHelpDialog';
import { Text } from '../Text';

function stopMicrophone(stream: MediaStream) {
  stream.getTracks().forEach(function (track) {
    track.stop();
  });
}

interface VoiceInputProps {
  audioRef: React.RefObject<HTMLAudioElement>;
  onRecordingStateChange: (isRecording: boolean) => void;
  disabled: boolean;
}

const VoiceInput: React.FC<VoiceInputProps> = ({ audioRef, onRecordingStateChange, disabled }) => {
  const [isHelpDialogOpen, setHelpDialogOpen] = useState(false);
  const [isPostProcessing, setIsPostProcessing] = useState(false);
  const [audioURL, setAudioURL] = useState<string>('');
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['stt']);
  const sourceLanguage = useSelector(selectSourceLanguage);
  const translateLanguage = useSelector(selectTranslateLanguage);
  const sessionId = useSelector(selectSessionId);
  const [isRecording, setIsRecording] = useState(false);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  const { startRecording, mediaBlobUrl, stopRecording, clearBlobUrl } = useStreamSTT(sourceLanguage, translateLanguage);

  ReactGA.send({ hitType: 'pageview', page: '/stt', title: 'STT Demo' });

  const { isMobile } = useWindowDimensions();

  const iconType = isMobile ? 'label2' : 'body1normal';

  const checkMicrophoneAvailability = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (error) {
      return false;
    }
  };

  const fetchBlob = async (url: string, type: string, name: string) => {
    dispatch(analyzeAction.setRealtimeStt([]));

    const response = await fetch(url);
    const responseBlob = await response.blob();

    setAudioURL(url);
    const file = new File([responseBlob], name, {
      type: type || 'audio/wav',
    });

    const formData = new FormData();
    formData.append('file', file);

    dispatch(analyzeAction.setCurrentAudioFilename(name));

    setIsPostProcessing(true);
    notifySuc(t('notificationMessageSttProcessing'));

    try {
      const data: DiarizeRequestDto = {
        formData: formData,
        sessionId: sessionId,
        filename: name,
        sourceLanguage: sourceLanguage,
        translateLanguage: translateLanguage,
      };

      await dispatch(getDiarizedFileAsync(data)).then(unwrapResult);
      notifySuc(t('successMessagePostProcessing'));
    } catch (error) {
      console.log(error);
      notifyErr(t('errorMessagePostProcessing'), '10');
    } finally {
      setIsPostProcessing(false);
      dispatch(getAllDiarizationsAsync());
    }
  };

  const onLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) {
      notifyErr(t('fileUploadError'), '10');
      return;
    }
    const blobLink = URL.createObjectURL(files[0]);
    fetchBlob(blobLink, files[0].type, files[0].name);
  };

  const handleOpenHelpDialog = () => {
    setHelpDialogOpen(true);
  };

  const handleCloseHelpDialog = () => {
    setHelpDialogOpen(false);
  };

  const handleUploadClick = () => {
    document.getElementById('file-upload')?.click();
  };

  const handleRecordClick = async () => {
    const hasMic = await checkMicrophoneAvailability();
    if (!hasMic) {
      notifyErr(t('noMicrophoneError'), '10');
      return;
    }

    if (!isRecording) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mediaStreamRef.current = stream;
          console.log('Media stream set:', stream);
          setIsRecording(true);
          startRecording();
          dispatch(analyzeAction.clearRealtimeStt());
          clearBlobUrl();
          onRecordingStateChange(true);
        })
        .catch((error) => {
          console.error('Error accessing media devices:', error);
        });
    } else {
      stopRecordingHandler();
    }
  };

  const stopRecordingHandler = () => {
    setIsRecording(false);
    stopRecording();
    onRecordingStateChange(true);
    if (mediaStreamRef.current) {
      stopMicrophone(mediaStreamRef.current);
      mediaStreamRef.current = null;
    }
  };

  const resetRecordingHandler = () => {
    dispatch(analyzeAction.clearRealtimeStt());
    clearBlobUrl();
  };

  useEffect(() => {
    return () => resetRecordingHandler();
  }, []);

  return (
    <VoiceInputContainer>
      {audioURL && !isPostProcessing && (
        <DetailedPlayback url={isRecording ? mediaBlobUrl : audioURL} audioRef={audioRef} />
      )}

      {!audioURL && !disabled && (
        <RecordButton onClick={handleRecordClick}>
          {isRecording ? <StopIcon /> : <RecordIcon />}
          {!isMobile && (
            <Text padding="5px" type={iconType}>
              {isRecording ? t('stopRecording') : t('startRecording')}
            </Text>
          )}
        </RecordButton>
      )}

      {!isRecording && !isPostProcessing && !audioURL && (
        <UploadButton onClick={handleUploadClick}>
          <DownloadIcon />
          {!isMobile && (
            <Text padding="5px" type={iconType}>
              {t('uploadAudioFile')}
            </Text>
          )}
        </UploadButton>
      )}

      {isPostProcessing && <LoadingSpinner />}

      <input type="file" id="file-upload" style={{ display: 'none' }} onChange={onLoadFile} accept="audio/*" />
      <Tooltip title={t('help')} arrow>
        <IconWrapper onClick={handleOpenHelpDialog}>
          <HelpIcon />
        </IconWrapper>
      </Tooltip>

      <STTHelpDialog open={isHelpDialogOpen} onClose={handleCloseHelpDialog} />
    </VoiceInputContainer>
  );
};

const VoiceInputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 20px;
  width: 70%;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  justify-content: space-between;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: 0 auto;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 90%;
    border-radius: 10px;
    padding: 10px;
    bottom: 0px;
  }
`;

const UploadButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.hoverGrey};
  }
`;

const RecordButton = styled(UploadButton)<{ isRecording: boolean }>`
  background-color: ${(props) => (props.isRecording ? props.theme.colors.recording : props.theme.colors.white)};
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    background-color: ${(props) => props.theme.colors.hoverGrey};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  gap: 5px;
  padding: 8px 12px;
  border-radius: 8px;
`;

export default VoiceInput;

import { Text } from 'components/atoms';
import { AuthorizedRoutes } from 'constants/routes';
import useRenderParagraphs from 'hooks/useRenderParagraphs';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ArrowIcon from 'icons/Arrow';
import RealGPTIcon from 'icons/RealGPT';
import RealSIDIcon from 'icons/RealSID';
import RealSTTIcon from 'icons/RealSTT';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { TranslationType } from 'types';

const LandingPage: React.FC = () => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();

  const { isMobile } = useWindowDimensions();

  const title = isMobile ? 'heading2' : 'title1';
  const textType = isMobile ? 'caption1' : 'label1normal';

  const boxes = [
    {
      route: AuthorizedRoutes.REAL_GPT,
      icon: <RealGPTIcon />,
      disabled: false,
      title: 'Real-GPT',
      text: useRenderParagraphs(t('realGPTDescription', { returnObjects: true }) as TranslationType),
    },
    {
      route: AuthorizedRoutes.REAL_STT,
      icon: <RealSTTIcon />,
      disabled: false,
      title: 'Real-STT',
      text: useRenderParagraphs(t('realSTTDescription', { returnObjects: true }) as TranslationType),
    },
    {
      route: '',
      disabled: true,
      icon: <RealSIDIcon />,
      title: 'Real-SID',
      text: useRenderParagraphs(t('realSIDDescription', { returnObjects: true }) as TranslationType),
    },
  ];

  const openLink = (url: string) => {
    if (url.includes('/')) {
      navigate(url);
    }
  };

  return (
    <Container>
      <TextBox>
        <Text type={title}>{useRenderParagraphs(t('welcome', { returnObjects: true }) as TranslationType)}</Text>
      </TextBox>
      <ButtonRow>
        {boxes.map((box) => (
          <Box key={box.title} onClick={() => openLink(box.route)} disabled={box.disabled}>
            <Icon>{box.icon}</Icon>
            <TextContent>
              <Text type={'heading2'} weight="bold">
                {box.title}
              </Text>
              <Text type={textType}>{box.text}</Text>
            </TextContent>
            <Icon>
              <ArrowIcon />
            </Icon>
          </Box>
        ))}
      </ButtonRow>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.lightGrey};

  @media only screen and ${deviceBreakpoints.mobile} {
    height: 100%;
  }
`;

const TextBox = styled.div`
  padding-bottom: 140px;
  text-align: center;
  wdith: 100%;
  @media only screen and ${deviceBreakpoints.mobile} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    padding-bottom: 40px;
  }
`;

const Box = styled.button<{ disabled: boolean }>`
  display: flex;
  width: 450px;
  height: 150px;
  padding: 20px;
  align-items: center;
  border-radius: 16px;
  border: none;
  text-align: left;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.08);
  gap: 12px;

  &:hover {
    background: ${(props) => (!props.disabled ? props.theme.colors.shadeGrey : props.theme.colors.white)};
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    height: 130px;
    padding: 10px;
    gap: 12px;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 40px;
    height: 40px;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--general-beta-08, 8px);
  flex: 1 0 0;

  @media only screen and ${deviceBreakpoints.mobile} {
    gap: 12px;
  }
`;

export default LandingPage;

import { Button, Text } from 'components/atoms';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ModelDialogProps {
  open: boolean;
  onClose: () => void;
  selectedModel: string;
  setSelectedModel: (model: string) => void;
}

const ModelDialog: React.FC<ModelDialogProps> = ({ open, onClose, selectedModel, setSelectedModel }) => {
  const { t } = useTranslation('gpt');
  const [localSelectedModel, setLocalSelectedModel] = useState(selectedModel);

  useEffect(() => {
    if (open) {
      setLocalSelectedModel(selectedModel);
    }
  }, [open, selectedModel]);

  const handleModelSelection = (model: string) => {
    setLocalSelectedModel(model);
  };

  const handleSave = () => {
    setSelectedModel(localSelectedModel);
    onClose();
  };

  if (!open) return null;

  return (
    <>
      <Backdrop onClick={onClose} />
      <FloatingBox>
        <StyledTitle>
          <Text type={'label1'} weight="bold">
            {t('modelSelectionTitle')}
          </Text>
        </StyledTitle>
        <ModelContainer>
          <ModelOption
            onClick={() => handleModelSelection('llama3-70b-8192')}
            selected={localSelectedModel === 'llama3-70b-8192'}>
            <Text type={'label1'}>{t('mainModel')}</Text>
          </ModelOption>
          <ModelOption onClick={() => handleModelSelection('aya:35b')} selected={localSelectedModel === 'aya:35b'}>
            <Text type={'label1'}>{t('bankModel')}</Text>
          </ModelOption>
        </ModelContainer>
        <StyledActions>
          <Button
            onClickAction={handleSave}
            buttonType="blue-white"
            text={t('close')}
            textColor="white"
            textType={'label2'}
            size={'small'}
            weight="bold"
          />
        </StyledActions>
      </FloatingBox>
    </>
  );
};

// Styled Components
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.backdrop};
  z-index: 999;
`;

const FloatingBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 400px;
`;

const StyledTitle = styled.div`
  width: 100%;
  text-align: left;
  background-color: ${(props) => props.theme.colors.lightGray};
  color: ${(props) => props.theme.colors.blue};
  padding: 10px;
  border-radius: 4px;
`;

const ModelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const ModelOption = styled.div<{ selected: boolean }>`
  padding: 15px;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.selected ? props.theme.colors.primary : '#ccc')};
  cursor: pointer;
  background-color: ${(props) => (props.selected ? props.theme.colors.primaryLight : 'white')};
  text-align: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryLight};
  }
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 10px;
`;

export default ModelDialog;

import { Bubble, STTDropdown, Text, VoiceInput } from 'components/atoms';
import { EmptyContent } from 'components/atoms/EmptyContent';
import { gaMeasurementId } from 'config';
import { actions as analyzeAction } from 'features/analyze';
import {
  selectCurrentResult,
  selectRealtimeData,
  selectSourceLanguage,
  selectTranslateLanguage,
} from 'features/analyze/selector';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ArrowIcon from 'icons/Arrow';
import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { RealtimeSTTData } from 'types';

ReactGA.initialize(gaMeasurementId);

const VoiceSection: React.FC = () => {
  ReactGA.send({ hitType: 'pageview', page: '/stt', title: 'STT Demo' });
  const { isMobile } = useWindowDimensions();
  const contentType = isMobile ? 'label1normal' : 'body1normal';
  const { t } = useTranslation('stt');

  const dispatch = useAppDispatch();
  const currentResult = useSelector(selectCurrentResult);
  const realtimeData = useSelector(selectRealtimeData);
  const [currentData, setCurrentData] = useState<RealtimeSTTData[]>([]);
  const [currentTranslation, setCurrentTranslation] = useState<RealtimeSTTData[]>([]);
  const sourceLanguage = useSelector(selectSourceLanguage);
  const translateLanguage = useSelector(selectTranslateLanguage);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [isRecording, setIsRecording] = useState(false);

  const handleRecordingStateChange = (isRecording: boolean) => {
    setIsRecording(isRecording);
  };

  const SupportedLanguages = [
    { value: 'ko', label: t('ko') },
    { value: 'en-US', label: t('en') },
    { value: 'ja', label: t('ja') },
    { value: 'zh', label: t('zh') },
    { value: 'ru', label: t('ru') },
    { value: 'ar', label: t('ar') },
  ];

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const bubbleRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    // Scroll to highlighted bubble when highlightedIndex changes
    if (highlightedIndex !== null && bubbleRefs.current[highlightedIndex]) {
      bubbleRefs.current[highlightedIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [highlightedIndex]);

  useEffect(() => {
    // Handle audio playback time updates
    const handleTimeUpdate = () => {
      if (audioRef.current) {
        console.log(audioRef.current.currentTime);
        const currentTime = audioRef.current.currentTime;

        // Find the index of the current segment being played
        const currentSegmentIndex = currentData.findIndex(
          (data) => currentTime >= data.start && currentTime < data.end,
        );

        // Update the highlighted index state
        setHighlightedIndex(currentSegmentIndex !== -1 ? currentSegmentIndex : null);
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [currentData]);

  useEffect(() => {
    if (currentResult && currentResult.diarization.length > 0) {
      const current = currentResult.diarization.map((d) => ({
        speaker: d.speakerId,
        words: d.words,
        isBookmarked: d.isBookmarked,
        start: d.start,
        end: d.end,
      }));
      setCurrentData(current);
      const translation = currentResult.translation.map((d) => ({
        speaker: d.speakerId,
        words: d.words,
        isBookmarked: d.isBookmarked,
        start: d.start,
        end: d.end,
      }));
      setCurrentTranslation(translation);
    }
  }, [dispatch, currentResult]);

  const setSourceLanguage = (value: string) => {
    dispatch(analyzeAction.setSourceLanguage(value));
  };

  const setTranslateLanguage = (value: string) => {
    dispatch(analyzeAction.setTranslateLanguage(value));
  };

  const joinedWords = realtimeData
    .map((data) => data.word)
    .join(' ')
    .replace(/\./g, '.\n');

  const joinedTranslations = realtimeData
    .map((data) => data.translate)
    .join(' ')
    .replace(/\./g, '.\n');

  const filteredTranslateLanguages = SupportedLanguages.filter((lang) => lang.value !== sourceLanguage);

  const checkSource = sourceLanguage === 'ar' || sourceLanguage === 'en-US';

  return (
    <Wrapper>
      <Container>
        <Content>
          <FullWidthBox>
            <Option>
              <STTDropdown
                options={SupportedLanguages}
                value={sourceLanguage}
                onChange={(value) => setSourceLanguage(value)}
                displayKey="label" // Display `label` in the dropdown
                valueKey="value" // Use `value` as the selected option key
              />
            </Option>
            <ArrowIconWrapper>
              <ArrowIcon />
            </ArrowIconWrapper>
            <Option>
              <STTDropdown
                options={filteredTranslateLanguages}
                value={translateLanguage}
                disabled={isRecording}
                onChange={(value) => setTranslateLanguage(value)}
                displayKey="label" // Display `label` in the dropdown
                valueKey="value" // Use `value` as the selected option key
              />
            </Option>
          </FullWidthBox>
          <BoxContainer>
            {realtimeData.length == 0 && currentData.length > 0 && (
              <>
                <Box>
                  {currentData.length > 0 &&
                    currentData.map((data, index) => (
                      <Bubble
                        ref={(el) => (bubbleRefs.current[index] = el)}
                        key={index}
                        title={data.speaker}
                        text={data.words}
                        start={data.start}
                        end={data.end}
                        translation={false}
                        highlighted={highlightedIndex === index}
                      />
                    ))}
                </Box>
                <Box>
                  {currentTranslation.map((translation, index) => (
                    <Bubble
                      ref={(el) => (bubbleRefs.current[index] = el)}
                      key={index}
                      title={translation.speaker}
                      text={translation.words}
                      start={translation.start}
                      end={translation.end}
                      translation={true}
                      highlighted={highlightedIndex === index}
                    />
                  ))}
                </Box>
              </>
            )}
            {realtimeData.length > 0 && (
              <>
                <Box>
                  <TextBox>
                    <Text type={contentType}>{joinedWords}</Text>
                  </TextBox>
                </Box>
                <Box>
                  <TextBox>
                    <Text type={contentType}>{joinedTranslations}</Text>
                  </TextBox>
                </Box>
              </>
            )}
            {!currentData.length && realtimeData.length == 0 && <EmptyContent />}
          </BoxContainer>
        </Content>
        <VoiceInput audioRef={audioRef} onRecordingStateChange={handleRecordingStateChange} disabled={checkSource} />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 15%;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    margin-left: unset;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin: 0 auto;
  width: 100%;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  justify-content: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
    padding-left: 0px;
    justify-content: flex-start;
    padding: 0px;
    height: 85vh;
    overflow-y: hidden;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 80vh;
  border-bottom: 0.1px solid ${(props) => props.theme.colors.shadeGrey};

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-grow: 1;
    max-height: calc(90vh - 100px);
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.7);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(100, 100, 100, 0.9);
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(240, 240, 240, 0.5);
    border-radius: 10px;
  }

  /* Fallback for non-WebKit browsers */
  scrollbar-width: thin;
  scrollbar-color: rgba(100, 100, 100, 0.7) rgba(240, 240, 240, 0.5);
`;

const FullWidthBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 10px;
`;

const Option = styled.div`
  flex: 2;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
`;

const ArrowIconWrapper = styled.div`
  flex: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

const TextBox = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.colors.white};
  padding: 15px;
  margin: 0 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  word-wrap: break-word;
`;

const Box = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.colors.white};
  height: 100%;
`;

export default VoiceSection;

import { Text } from 'components/atoms';
import CopyIcon from 'icons/Copy';
import React from 'react';
import styled from 'styled-components';

interface MessageBubbleProps {
  sender: string;
  text: string;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ sender, text }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(text || '');
  };

  return (
    <MessageBubbleWrapper sender={sender}>
      <TextWrapper>
        <Text type={'body1reading'}>{text}</Text>
      </TextWrapper>
      {(sender == 'llm' || sender == 'assistant') && (
        <Icon onClick={handleCopy}>
          <CopyIcon />
        </Icon>
      )}
    </MessageBubbleWrapper>
  );
};

const MessageBubbleWrapper = styled.div<{ sender: string }>`
  align-self: ${(props) => (props.sender === 'user' ? 'flex-end' : 'flex-start')};
  display: flex;
  max-width: 500px;
  flex-direction: column;
  padding: var(--Spacing-ml, 20px);
  gap: 8px;
  border-radius: var(--Radius-m, 12px);
  background: ${(props) => props.theme.colors.black};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  position: relative;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.div`
  display: flex;
  align-items: left;
  cursor: pointer;
`;

export default MessageBubble;

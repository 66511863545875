import React from 'react';

const RealSIDIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill="#F3F1FC" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        x="12"
        y="12"
        transform="translate(-12 -12)">
        <path
          d="M8.00102 23.5C8.55102 23.5 9.00102 23.05 9.00102 22.5C9.00102 21.95 8.55102 21.5 8.00102 21.5C7.45102 21.5 7.00102 21.95 7.00102 22.5C7.00102 23.05 7.45102 23.5 8.00102 23.5ZM12.001 23.5C12.551 23.5 13.001 23.05 13.001 22.5C13.001 21.95 12.551 21.5 12.001 21.5C11.451 21.5 11.001 21.95 11.001 22.5C11.001 23.05 11.451 23.5 12.001 23.5ZM16.001 23.5C16.551 23.5 17.001 23.05 17.001 22.5C17.001 21.95 16.551 21.5 16.001 21.5C15.451 21.5 15.001 21.95 15.001 22.5C15.001 23.05 15.451 23.5 16.001 23.5ZM9.00102 9.5V3.5C9.00102 1.84 10.341 0.5 12.001 0.5C13.661 0.5 15.001 1.84 15.001 3.5V9.5C15.001 11.16 13.661 12.5 12.001 12.5C10.341 12.5 9.00102 11.16 9.00102 9.5ZM17.911 9.5C18.521 9.5 19.001 10.04 18.911 10.64C18.421 13.64 16.021 15.98 13.001 16.42V18.5C13.001 19.05 12.551 19.5 12.001 19.5C11.451 19.5 11.001 19.05 11.001 18.5V16.42C7.98102 15.98 5.58102 13.64 5.09102 10.64C4.99102 10.04 5.48102 9.5 6.09102 9.5C6.58102 9.5 6.99102 9.86 7.07102 10.35C7.48102 12.71 9.53102 14.5 12.001 14.5C14.471 14.5 16.521 12.71 16.931 10.35C17.011 9.86 17.421 9.5 17.911 9.5Z"
          fill="#624AC6"
        />
      </svg>
    </svg>
  );
};

export default RealSIDIcon;

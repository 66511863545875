import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { LoadingStatus } from '../../types';
import { createNewSessionAsync, getAllDiarizationsAsync, getDiarizedFileAsync, getTranslationAsync } from './thunks';
import { AnalyzeStore, RealtimeSttType } from './types';

const initialState: AnalyzeStore = {
  diarizations: [],
  currentAudioFilename: '',
  currentResult: {
    diarization: [],
    translation: [],
  },
  realtimeStt: [],
  translation: '',
  sourceLanguage: 'ko',
  translateLanguage: 'en-US',
  loadingStatus: LoadingStatus.idle,
  sessionId: uuidv4(),
  error: null,
};

const analyzeSlice = createSlice({
  name: 'analyze',
  initialState,
  reducers: {
    setRealtimeStt: (state, { payload }: PayloadAction<RealtimeSttType>) => {
      return {
        ...state,
        realtimeStt: state.realtimeStt.concat(payload),
      };
    },
    clearRealtimeStt: (state) => {
      return {
        ...state,
        realtimeStt: [],
      };
    },
    setCurrentAudioFilename: (state, { payload }: PayloadAction<string>) => {
      return { ...state, currentAudioFilename: payload };
    },
    setSourceLanguage: (state, { payload }: PayloadAction<string>) => {
      return { ...state, sourceLanguage: payload };
    },
    setTranslateLanguage: (state, { payload }: PayloadAction<string>) => {
      return { ...state, translateLanguage: payload };
    },
    updateDiarizationOfFile: (state, action) => {
      return {
        ...state,
        diarizedResult: action.payload,
      };
    },
    updateCurrentResultBySessionId: (state, action: PayloadAction<string>) => {
      const sessionId = action.payload;
      const foundDiarization = state.diarizations.find((d) => d.sessionId === sessionId);

      if (foundDiarization) {
        state.currentResult = {
          diarization: foundDiarization.diarization || [],
          translation: foundDiarization.translation || [],
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDiarizedFileAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getDiarizedFileAsync.fulfilled, (state, { payload }) => {
      return { ...state, currentResult: payload, loadingStatus: LoadingStatus.idle };
    });
    builder.addCase(getDiarizedFileAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(getAllDiarizationsAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getAllDiarizationsAsync.fulfilled, (state, { payload }) => {
      return { ...state, diarizations: payload.diarizations, loadingStatus: LoadingStatus.idle };
    });
    builder.addCase(getAllDiarizationsAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(createNewSessionAsync.fulfilled, (state, action) => {
      state.currentResult = {
        diarization: [],
        translation: [],
      };
      state.sessionId = action.payload;
    });
    builder.addCase(getTranslationAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getTranslationAsync.fulfilled, (state, { payload }) => {
      return { ...state, translation: payload.translation, loadingStatus: LoadingStatus.idle };
    });
    builder.addCase(getTranslationAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
  },
});

export const actions = {
  ...analyzeSlice.actions,
};
export const { reducer } = analyzeSlice;

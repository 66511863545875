import React from 'react';

interface SendIconProps {
  filled: boolean;
}

const SendIcon: React.FC<SendIconProps> = ({ filled }) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="18" fill={filled ? '#1A2B7C' : 'none'} />
      <svg xmlns="http://www.w3.org/2000/svg" x="9" y="8" width="18" height="20" viewBox="0 0 18 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.91315 3.89458C2.60914 3.14844 3.30687 2.38596 3.97324 2.73611L15.4899 8.78755C16.0945 9.10524 16.0945 10.0611 15.4898 10.3787L3.90535 16.4631C3.23604 16.8147 2.53718 16.0445 2.84811 15.298L5.22961 9.57983L2.91315 3.89458ZM13.9722 9.58297L4.60793 14.5013L6.34906 10.3208H10.1022V8.88077H6.36741L4.65977 4.68975L13.9722 9.58297Z"
          fill="white"
        />
      </svg>
    </svg>
  );
};

export default SendIcon;

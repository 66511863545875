import { useEffect } from 'react';
import debounce from 'utils/debounce';

export default function useSetMobileViewport() {
  const listener = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  const debouncedListener = debounce(listener, 100);

  useEffect(() => {
    listener();
    window.addEventListener('resize', debouncedListener);

    return () => window.removeEventListener('resize', debouncedListener);
  }, []);
}

import { Button, Text } from 'components/atoms';
import { actions as analyzeAction } from 'features/analyze';
import { selectDiarizations } from 'features/analyze/selector';
import { createNewSessionAsync, deleteSessionAsync, getAllDiarizationsAsync } from 'features/analyze/thunks';
import DeleteIcon from 'icons/Delete';
import DotsIcon from 'icons/Dots';
import DownloadIcon from 'icons/Download';
import PlusIcon from 'icons/Plus';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { Diarization } from 'types';
import { formatIsoToCustomDate } from 'utils/time';

const Sidebar: React.FC = () => {
  const { t } = useTranslation(['stt']);
  const diarizations = useSelector(selectDiarizations);
  const dispatch = useAppDispatch();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenDropdown(null);
      }
    };

    if (openDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdown]);

  useEffect(() => {
    dispatch(getAllDiarizationsAsync());
  }, [dispatch]);

  const handleHistoryClick = (sessionId: string) => {
    // Dispatch the action to update current result using session id
    dispatch(analyzeAction.updateCurrentResultBySessionId(sessionId));
  };

  const handleNewSessionClick = () => {
    dispatch(createNewSessionAsync());
    window.location.reload();
  };

  const toggleDropdown = (sessionId: string) => {
    setOpenDropdown(openDropdown === sessionId ? null : sessionId);
  };

  const deleteSessionChat = (sessionId: string) => {
    dispatch(deleteSessionAsync(sessionId));
    dispatch(getAllDiarizationsAsync());
  };

  const formatDiarizationToSrt = (diarizations: Diarization[]) => {
    return diarizations
      .map((entry: { start: number; end: number; speakerName: any; words: any }, index: number) => {
        const start = new Date(entry.start * 1000).toISOString().substr(11, 12).replace('.', ',');
        const end = new Date(entry.end * 1000).toISOString().substr(11, 12).replace('.', ',');
        return `${index + 1}\n${start} --> ${end}\n${entry.words}\n`;
      })
      .join('\n');
  };

  const downloadSRT = (sessionId: string) => {
    const diarization = diarizations.find((d) => d.sessionId === sessionId);
    if (!diarization) return;

    const srtContent = formatDiarizationToSrt(diarization.diarization);
    const blob = new Blob([srtContent], { type: 'text/srt;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const filenameWithoutExtension = diarization.filename.replace(/\.[^/.]+$/, '');
    link.download = `${filenameWithoutExtension}.srt`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const downloadTranslateSRT = (sessionId: string) => {
    const diarization = diarizations.find((d) => d.sessionId === sessionId);
    if (!diarization) return;

    const srtContent = formatDiarizationToSrt(diarization.translation);
    const blob = new Blob([srtContent], { type: 'text/srt;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const filenameWithoutExtension = diarization.filename.replace(/\.[^/.]+$/, '');
    link.download = `${filenameWithoutExtension}.srt`;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <SidebarContainer>
      <ButtonWrapper>
        <Button
          onClickAction={handleNewSessionClick}
          buttonType="transparent-grey"
          icon={<PlusIcon />}
          text={t('newSession')}
          textColor="black"
          textType={'label2'}
          padding="var(--Radius-Sharp, 0px) var(--Spacing-ml, 20px)"
          size={'small'}
          weight="bold"
          width="100%"
        />
      </ButtonWrapper>
      <ChatsList>
        <Text type={'label1'} weight="bold">
          {t('voiceHistory')}
        </Text>
        {diarizations.map((diarization) => (
          <HistoryBox key={diarization.sessionId}>
            <TextContainer onClick={() => handleHistoryClick(diarization.sessionId)}>
              <Text type={'label1reading'} textColor="grey">
                {formatIsoToCustomDate(diarization.date)}
              </Text>
              <Text type={'body1reading'}>{diarization.filename}</Text>
            </TextContainer>
            <Icon onClick={() => toggleDropdown(diarization.sessionId)}>
              <DotsIcon />
            </Icon>
            {openDropdown === diarization.sessionId && (
              <DropdownMenu ref={dropdownRef}>
                <DropdownMenuItem onClick={() => downloadSRT(diarization.sessionId)}>
                  <IconContainer>
                    <DownloadIcon />
                  </IconContainer>
                  <Text type="body2normal" weight="bold" hoverColor="blue">
                    {t('download')}
                  </Text>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => downloadTranslateSRT(diarization.sessionId)}>
                  <IconContainer>
                    <DownloadIcon />
                  </IconContainer>
                  <Text type="body2normal" weight="bold" hoverColor="blue">
                    {t('downloadTranslation')}
                  </Text>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => deleteSessionChat(diarization.sessionId)}>
                  <IconContainer>
                    <DeleteIcon />
                  </IconContainer>
                  <Text type="body2normal" weight="bold" hoverColor="blue">
                    {t('delete')}
                  </Text>
                </DropdownMenuItem>
              </DropdownMenu>
            )}
          </HistoryBox>
        ))}
      </ChatsList>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  position: fixed;
  padding: 10px;
  top: 80px;
  left: 0;
  height: calc(100vh - 80px);
  width: 15%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  z-index: 100;
  border-right: 1px solid ${(props) => props.theme.colors.shadeGrey};
  overflow-y: auto;
  flex-shrink: 0;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 100%;
  padding-bottom: 20px;
`;

const ChatsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
`;

const HistoryBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;
  position: relative;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const DropdownMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: var(--Radius-m, 12px);
  background: white;
  position: fixed;
  left: 300px;
  width: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
`;

const DropdownMenuItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  align-items: center;
  margin: 8px;
  gap: 5px;

  &:hover {
    background-color: ${(props) => props.theme.colors.shadeGrey};
  }
`;

export default Sidebar;

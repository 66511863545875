export const AuthorizedRoutes = {
  LANDING_PAGE: '/home',
  REAL_STT: '/stt',
  REAL_GPT: '/gpt',
  REAL_SID: '/sid/speakers',
  REAL_SID_NEW_SPEAKER: '/sid/new-speaker',
  REAL_SID_PROCESS_PAGE: '/sid/process',
};

export const UnauthorizedRoutes = {
  SIGN_IN: '/auth/sign-in',
  RESET_PASSWORD: '/auth/reset',
  SIGN_UP: '/auth/sign-up',
  VERIFICATION: '/auth/verification',
  REQUEST_VERIFICATION_LINK: '/auth/request-verification-link',
};

import PauseIcon from 'icons/Pause';
import PlayIcon from 'icons/Play';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import WaveSurfer from 'wavesurfer.js';

interface DetailedPlaybackProps {
  url: string | null;
  audioRef: React.RefObject<any>;
}

const DetailedPlayback: React.FC<DetailedPlaybackProps> = ({ url, audioRef }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0); // State for current time
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    if (waveformRef.current && url) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: 'grey',
        progressColor: 'blue',
        barWidth: 2,
        height: 40,
        responsive: true,
      });

      wavesurferRef.current.load(url);

      wavesurferRef.current.on('ready', () => {
        setDuration(wavesurferRef.current!.getDuration());
      });

      return () => {
        wavesurferRef.current?.destroy();
      };
    }
  }, [url]);

  useEffect(() => {
    if (wavesurferRef.current) {
      const updateAudioTime = () => {
        const currentTime = wavesurferRef.current?.getCurrentTime();
        if (currentTime !== undefined) {
          setCurrentTime(currentTime);
          if (audioRef.current) {
            audioRef.current.currentTime = currentTime; // Sync audio reference
          }
        }
      };

      wavesurferRef.current.on('audioprocess', updateAudioTime);
      wavesurferRef.current.on('seek', updateAudioTime);
      wavesurferRef.current.on('finish', () => setIsPlaying(false));

      return () => {
        wavesurferRef.current?.un('audioprocess', updateAudioTime);
        wavesurferRef.current?.un('seek', updateAudioTime);
      };
    }
  }, [audioRef]);

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      if (isPlaying) {
        wavesurferRef.current.pause();
      } else {
        wavesurferRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Format time in MM:SS
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <PlaybackContainer>
      <audio ref={audioRef} src={url ? url : undefined} preload="metadata" />
      <PlayPauseButton onClick={handlePlayPause}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</PlayPauseButton>
      <TimeMarker>{formatTime(currentTime)}</TimeMarker> {/* Updated to use currentTime state */}
      <WaveformContainer ref={waveformRef} />
      <TimeMarker>{formatTime(duration)}</TimeMarker>
    </PlaybackContainer>
  );
};

const PlaybackContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const WaveformContainer = styled.div`
  flex: 1;
  overflow: hidden;
  max-width: 100%;

  overflow-x: hidden;
  & wave {
    overflow: hidden !important;
  }
`;

const TimeMarker = styled.span`
  margin: 0 10px;
`;

const PlayPauseButton = styled.div`
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

export default DetailedPlayback;

import { unwrapResult } from '@reduxjs/toolkit';
import { baseDeepgram, options } from 'config';
import { actions as analyzeAction } from 'features/analyze';
import { getTranslationAsync } from 'features/analyze/thunks';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'store/reducers';

import useMediaRecorder from './useMediaRecorder';

export type Words = Array<{
  confidence: number;
  end: number;
  punctuated_word: string;
  speaker: number;
  start: number;
  word: string;
}>;

export default function useStreamSTT(sourceLanguage: string, targetLanguage: string) {
  const socket = useRef<WebSocket>();
  const mediaRecorder = useRef<MediaRecorder>();
  const [socketCount, setSocketCount] = useState(0);

  const dispatch = useAppDispatch();
  const {
    mediaStream,
    startRecording,
    mediaBlobUrl,
    clearBlobUrl,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
  } = useMediaRecorder();

  useEffect(() => {
    if (mediaStream && mediaStream.active) {
      socket.current = new WebSocket(baseDeepgram + sourceLanguage + options);

      mediaRecorder.current = new MediaRecorder(mediaStream);
      mediaRecorder.current.start();
    }
  }, [mediaStream]);

  useEffect(() => {
    if (mediaStream && mediaStream.active) {
      const interval = setInterval(() => {
        if (mediaRecorder.current!.state !== 'inactive') {
          mediaRecorder.current!.requestData();
        }
      }, 1000);

      socket.current!.onopen = () => {
        console.log('connected');
      };

      socket.current!.onclose = () => {
        console.log('closed');
        if (mediaRecorder.current!.state === 'paused') {
          console.log('new socket');
          socket.current = new WebSocket(baseDeepgram);
          setSocketCount((prev) => ++prev);
        }

        if (mediaRecorder.current!.state !== 'inactive') {
          mediaRecorder.current!.stop();
          clearInterval(interval);
        }
      };

      socket.current!.onerror = (error) => {
        console.log('ws error: ', error);

        if (mediaRecorder.current!.state !== 'inactive') {
          mediaRecorder.current!.stop();
        }

        clearInterval(interval);
      };

      socket.current!.onmessage = (data) => {
        const parsedData = JSON.parse(data.data);

        if (parsedData.is_final == true && parsedData.channel.alternatives[0].transcript.length > 0) {
          const transcript: string = parsedData.channel.alternatives[0].transcript;
          if (sourceLanguage !== targetLanguage) {
            dispatch(
              getTranslationAsync({
                originalText: transcript,
                sourceLanguage: sourceLanguage,
                targetLanguage: targetLanguage,
              }),
            )
              .then(unwrapResult)
              .then((data) => {
                const word = {
                  speaker: 0,
                  word: transcript,
                  translate: data.translation,
                  start: parsedData.channel.alternatives[0].start,
                  end: parsedData.channel.alternatives[0].end,
                };
                dispatch(analyzeAction.setRealtimeStt([word]));
              });
          } else {
            const word = {
              speaker: 0,
              word: parsedData.channel.alternatives[0].transcript,
              translate: parsedData.channel.alternatives[0].transcript,
              start: parsedData.channel.alternatives[0].start,
              end: parsedData.channel.alternatives[0].end,
            };
            dispatch(analyzeAction.setRealtimeStt([word]));
          }
        }
      };

      mediaRecorder.current!.ondataavailable = (e: BlobEvent) => {
        setTimeout(() => {
          if (e.data.size !== 0) {
            const blob = e.data;
            console.log(blob);
            socket.current!.send(blob);
          }
        }, 500);
      };
    }
  }, [mediaStream, socketCount]);

  return {
    startRecording,
    mediaBlobUrl,
    clearBlobUrl,
    stopRecording,
    pauseRecording,
    resumeRecording,
    savedMediaRecorder: mediaRecorder.current,
    status,
  };
}

import React from 'react';

const TXTIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M2.5 6C2.5 6.83 3.17 7.5 4 7.5H7.5V18C7.5 18.83 8.17 19.5 9 19.5C9.83 19.5 10.5 18.83 10.5 18V7.5H14C14.83 7.5 15.5 6.83 15.5 6C15.5 5.17 14.83 4.5 14 4.5H4C3.17 4.5 2.5 5.17 2.5 6ZM20 9.5H14C13.17 9.5 12.5 10.17 12.5 11C12.5 11.83 13.17 12.5 14 12.5H15.5V18C15.5 18.83 16.17 19.5 17 19.5C17.83 19.5 18.5 18.83 18.5 18V12.5H20C20.83 12.5 21.5 11.83 21.5 11C21.5 10.17 20.83 9.5 20 9.5Z"
        fill="#2E2F33"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default TXTIcon;

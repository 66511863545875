import emailjs from '@emailjs/browser';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button } from 'components/atoms';
import InputField from 'components/atoms/InputField/InputField';
import { Text } from 'components/atoms/Text';
import { emailJSPublicKey, emailJSServiceId, emailJSTemplateId, verificationTokenURL } from 'config';
import { UnauthorizedRoutes } from 'constants/routes';
import { selectLoadingStatus } from 'features/auth/selectors';
import { signUpAsync } from 'features/auth/thunks';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { LoadingStatus } from 'types';
import { notifyErr } from 'utils/notification';
import { patterns } from 'utils/patterns';

interface FormValue {
  email: string;
  emailConfirm: string;
  name: string;
  password: string;
  passwordConfirm: string;
}

const RegistrationForm = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();
  const loadingStatus = useSelector(selectLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const [isDataSent, setIsDataSent] = useState(false);
  const [emailIsSending, setEmailIsSending] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValue>({
    defaultValues: { name: '', email: '', emailConfirm: '', password: '', passwordConfirm: '' },
    mode: 'onChange',
  });
  const fieldEmailValue = watch('email');

  const onSubmit = async (data: FormValue) => {
    setEmailIsSending(true);
    try {
      const response = await dispatch(signUpAsync({ name: data.name, email: data.email, password: data.password }));
      unwrapResult(response);

      await emailjs.send(
        emailJSServiceId,
        emailJSTemplateId,
        {
          link: `${verificationTokenURL}/auth/verification?token=${response.payload.data.token}`,
          recipientEmail: fieldEmailValue,
          linkName: verificationTokenURL,
        },
        emailJSPublicKey,
      );

      setIsDataSent(true);
    } catch (error) {
      setEmailIsSending(false);
      if (error?.statusCode === 409) {
        notifyErr(t('errorMessageRegistrationExistEmail'), '3');
      } else {
        notifyErr(t('errorMessageRegistration'), '4');
      }
    } finally {
      setEmailIsSending(false);
    }
  };

  if (isDataSent) {
    return (
      <Root>
        <Text type={''}>{t('signUpThanks')}</Text>
        <LinkWrapper>
          <StyledLink to={UnauthorizedRoutes.SIGN_IN}>{t('logInHere')}</StyledLink>
        </LinkWrapper>
      </Root>
    );
  }

  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <Text type="title1">{t('signUp')}</Text>
      <InputField
        control={control}
        name="name"
        rules={{ required: { value: true, message: t('requiredField') } }}
        label={t('name')}
      />
      <InputField
        control={control}
        name="email"
        rules={{
          required: { value: true, message: t('requiredField') },
          pattern: { value: patterns.email, message: t('invalidEmailAddressFormat') },
        }}
        label={t('email')}
      />
      <InputField
        control={control}
        name="emailConfirm"
        rules={{
          required: { value: true, message: t('requiredField') },
          validate: (value: string, formValues: FormValue) => value === formValues.email || t('emailsMustMatch')!,
        }}
        label={t('confirmEmail')}
      />
      <InputField
        type="password"
        control={control}
        name="password"
        rules={{ required: { value: true, message: t('requiredField') } }}
        label={t('password')}
      />
      <InputField
        type="password"
        control={control}
        name="passwordConfirm"
        rules={{
          required: { value: true, message: t('requiredField') },
          validate: (value: string, formValues: FormValue) => value === formValues.password || t('passwordsMustMatch')!,
        }}
        label={t('confirmPassword')}
      />
      <Button
        text={t('signUp').toUpperCase()}
        disabled={isLoading || emailIsSending || !!errors.passwordConfirm}
        buttonType={'blue-white'}
        textType="body1normal"
        textColor="white"
        size="large"
        width="100%"
        weight={'bold'}
      />
      <LinkWrapper>
        <Text type={''}>{t('accountExistQuestion')}</Text>
        <StyledLink to={UnauthorizedRoutes.SIGN_IN}>{t('logInHere')}</StyledLink>
      </LinkWrapper>
    </Root>
  );
};

const Root = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
  align-items: center;
  @media (max-width: 765px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 52px;
  padding-top: 20px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default RegistrationForm;

import Text from 'components/atoms/Text/Text';
import ChevronIcon from 'icons/Chevron';
import FlagIcon from 'icons/Flag';
import i18n, { userLanguage } from 'locales/i18n';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

interface Icon {
  key: string;
  value: React.ReactNode;
  text: string;
}

const LanguageDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const dropdownLanguageItems = [
    { key: 'ko', value: <FlagIcon language="ko" />, text: 'KO' },
    { key: 'en', value: <FlagIcon language="en" />, text: 'EN' },
  ];

  // Load language preference from localStorage on mount
  useEffect(() => {
    const language = localStorage.getItem('lng');
    const initialLanguage =
      dropdownLanguageItems.find((d) => d.key === language) ||
      dropdownLanguageItems.find((d) => d.key === userLanguage) ||
      dropdownLanguageItems[0];

    setCurrentLanguage(initialLanguage);
  }, []);

  const [currentLanguage, setCurrentLanguage] = useState<Icon>(dropdownLanguageItems[0]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const changeLanguage = (languageSetting: Icon) => {
    setCurrentLanguage(languageSetting);
    localStorage.setItem('lng', languageSetting.key);
    i18n.changeLanguage(languageSetting.key);
    setIsOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={ref}>
      <DropdownButton ref={buttonRef} onClick={toggleDropdown}>
        <IconContainer>{currentLanguage.value}</IconContainer>
        <Text type="body1normal" weight="bold" pressedColor="blue">
          {currentLanguage.text}
        </Text>
        <ChevronIconWrapper isOpen={isOpen}>
          <ChevronIcon />
        </ChevronIconWrapper>
      </DropdownButton>
      <DropdownMenu isOpen={isOpen}>
        {dropdownLanguageItems.map((item) => (
          <DropdownMenuItem key={item.key} onClick={() => changeLanguage(item)}>
            <IconContainer>{item.value}</IconContainer>
            <Text type="body2normal" weight="bold" hoverColor="blue">
              {item.text}
            </Text>
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

const DropdownButton = styled.button`
  border: none;
  background: none;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100px;
  justify-content: space-between;
  gap: 5px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const ChevronIconWrapper = styled.div<{ isOpen: boolean }>`
  margin-left: auto;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownMenu = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: var(--Radius-m, 12px);
  background: white;
  position: absolute;
  top: 60px;
  width: 100px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
`;

const DropdownMenuItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  align-items: center;
  margin: 8px;
  gap: 5px;

  &:hover {
    background-color: ${(props) => props.theme.colors.shadeGrey};
  }
`;

export default LanguageDropdown;

import React from 'react';

const RecordIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <circle cx="18" cy="18" r="18" fill="#FED7D7" />
      <circle cx="18" cy="18" r="10" fill="#E53E3E" />
    </svg>
  );
};

export default RecordIcon;

import React from 'react';

const PlayIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <circle cx="18" cy="18" r="18" fill="#1A2B7C" />
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" x="6" y="6">
        <path
          d="M6.92749 6.82172V17.1817C6.92749 17.9717 7.79749 18.4517 8.46749 18.0217L16.6075 12.8417C17.2275 12.4517 17.2275 11.5517 16.6075 11.1517L8.46749 5.98172C7.79749 5.55172 6.92749 6.03172 6.92749 6.82172Z"
          fill="white"
        />
      </svg>
    </svg>
  );
};

export default PlayIcon;

import { AxiosResponse } from 'axios';
import { gptHttp } from 'services/http';
import { Conversation, ResponseUnionType } from 'types';

export interface UploadFilesDto {
  formData: FormData;
}

export interface UploadFilesResponseDto {
  content: string[];
}

export interface UploadURLDto {
  url: string;
}

export interface UploadURLResponseDto {
  content: string[];
}

export type AddMessageToChatDto = {
  rag: boolean;
  sessionId: string;
  prompt: string;
  model?: string;
  database?: string;
  graph?: string;
};

export type AddMessageToChatResDto = {
  conversation: Conversation[];
};

export interface UserConversationHistory {
  sessionId: string;
  date: string;
  conversation: Conversation[];
}

export interface Upload {
  updated: string;
  filename: string;
  status: string;
  file_type: string;
  upload_id: string;
}

export interface GetUserChatsResDto {
  conversations: UserConversationHistory[];
}

export interface GetUploadsResDto {
  uploads: Upload[];
}

export interface GetSummaryResDto {
  process: boolean;
}

export interface SummaryResDto {
  summary: string;
  upload_id: string;
  file_name: string;
}

export interface GetAllSummaryResDto {
  summaries: SummaryResDto[];
}

export interface UpdateChatNameDto {
  sessionId: string;
  name: string;
}

export const ChatApi = {
  addMessageToChat: async (data: AddMessageToChatDto): Promise<AxiosResponse<AddMessageToChatResDto>> => {
    return gptHttp.post('generate', data);
  },
  uploadFiles: async (data: UploadFilesDto): Promise<AxiosResponse<ResponseUnionType<UploadFilesResponseDto>>> => {
    return gptHttp.post('upload_files', data.formData, {
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
    });
  },
  uploadURL: async (data: UploadURLDto): Promise<AxiosResponse<ResponseUnionType<UploadURLResponseDto>>> => {
    return gptHttp.post('upload_url', data);
  },
  getUploads: async (): Promise<AxiosResponse<GetUploadsResDto>> => {
    return gptHttp.get('uploads');
  },
  getChats: async (): Promise<AxiosResponse<GetUserChatsResDto>> => {
    return gptHttp.get('user_chats');
  },
  getSessionChat: async (session_id: string): Promise<AxiosResponse<UserConversationHistory>> => {
    return gptHttp.get(`chat/${session_id}`);
  },
  getSummary: async (upload_id: string): Promise<AxiosResponse<GetSummaryResDto>> => {
    return gptHttp.get(`summarize/${upload_id}`);
  },
  getSummarys: async (): Promise<AxiosResponse<GetAllSummaryResDto>> => {
    return gptHttp.get('summarize');
  },
  deleteUploads: async (): Promise<AxiosResponse<void>> => {
    return gptHttp.delete('uploads');
  },
  updateName: async (data: UpdateChatNameDto): Promise<AxiosResponse<void>> => {
    return gptHttp.post('chat/update_name', data);
  },
  deleteChat: async (session_id: string): Promise<AxiosResponse<void>> => {
    return gptHttp.delete(`chat/${session_id}`);
  },
};

import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { AnalyzeStore } from './types';

export const selectAnalyze = (state: RootState): AnalyzeStore => state.analyze;
export const selectRealtimeData = createSelector(selectAnalyze, (analyze) => analyze.realtimeStt);
export const selectCurrentResult = createSelector(selectAnalyze, (analyze) => analyze.currentResult);
export const selectDiarizations = createSelector(selectAnalyze, (analyze) => analyze.diarizations);
export const selectLoadingStatus = createSelector(selectAnalyze, (analyze) => analyze.loadingStatus);
export const selectSourceLanguage = createSelector(selectAnalyze, (analyze) => analyze.sourceLanguage);
export const selectSessionId = createSelector(selectAnalyze, (analyze) => analyze.sessionId);
export const selectTranslateLanguage = createSelector(selectAnalyze, (analyze) => analyze.translateLanguage);
export const selectCurrentAudioFilename = createSelector(selectAnalyze, (analyze) => analyze.currentAudioFilename);

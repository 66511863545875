import React from 'react';
import styled from 'styled-components';

interface DropdownOption {
  [key: string]: string;
}

const STTDropdown: React.FC<{
  options: DropdownOption[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  displayKey: string;
  valueKey: string;
}> = ({ options, value, onChange, disabled, displayKey, valueKey }) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <DropdownWrapper>
      <StyledSelect value={value} onChange={handleChange} disabled={disabled}>
        {options.map((option, index) => (
          <StyledOption key={index} value={option[valueKey]}>
            {option[displayKey]}
          </StyledOption>
        ))}
      </StyledSelect>
      <ArrowIcon />
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledSelect = styled.select`
  border: none;
  outline: none;
  background: transparent;
  appearance: none;
  padding: 5px;
  font-size: 16px;
  padding-right: 30px;
`;

const StyledOption = styled.option`
  background-color: white;
  color: black;
  border: none;
  outline: none;
`;

const ArrowIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  transform: translateY(-50%);
`;

export default STTDropdown;

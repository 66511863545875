import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { ChatStore } from './types';

export const selectChats = (state: RootState): ChatStore => state.chat;
export const selectCurrentConversation = createSelector(selectChats, (chat) => chat.currentConversation);
export const selectUserChats = createSelector(selectChats, (chat) => chat.userChats);
export const selectSummary = createSelector(selectChats, (chat) => chat.summaries);
export const selectUploadFiles = createSelector(selectChats, (chat) => chat.uploadFiles);
export const selectUploads = createSelector(selectChats, (chat) => chat.uploads);
export const selectRAG = createSelector(selectChats, (chat) => chat.rag);
export const selectLoadingStatus = createSelector(selectChats, (chat) => chat.loadingStatus);
export const selectSessionId = createSelector(selectChats, (chat) => chat.sessionId);

import { Upload } from 'api/chat.api';
import { Button, LoadingSpinner, Text } from 'components/atoms';
import { selectUploads } from 'features/chat/selector';
import { deleteUploadsAsync, getUploadsAsync } from 'features/chat/thunks';
import PdfIcon from 'icons/PDF';
import TxtIcon from 'icons/TXT';
import UrlIcon from 'icons/URL';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

import images from '../../../assets/images/file_types.png';

interface SourceDialogProps {
  open: boolean;
  onClose: () => void;
  onLoadFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLoadURL: (url: string) => void;
  uploadSuccess: boolean;
}

const SourceDialog: React.FC<SourceDialogProps> = ({ open, onClose, onLoadFile, onLoadURL, uploadSuccess }) => {
  const { t } = useTranslation('gpt');
  const [urlInput, setUrlInput] = useState('');
  const currentUploads = useSelector(selectUploads);
  const [uploads, setUploads] = useState<Upload[]>([]);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [urlLoading, setUrlLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      dispatch(getUploadsAsync());
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (currentUploads) {
      setUploads(currentUploads);
    }
  }, [currentUploads]);

  useEffect(() => {
    if (uploadSuccess) {
      setLoading(false);
      setUrlLoading(false);
      dispatch(getUploadsAsync());
      onClose();
    }
  }, [uploadSuccess, onClose]);

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrlInput(event.target.value);
  };

  const deleteUploads = () => {
    dispatch(deleteUploadsAsync());
    onClose();
    window.location.reload();
  };

  const handleUrlSubmit = () => {
    setUrlLoading(true);
    onLoadURL(urlInput);
    setUrlInput('');
  };

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    onLoadFile(event);
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getIconByFileType = (fileType: string) => {
    switch (fileType) {
      case 'pdf':
        return <PdfIcon />;
      case 'txt':
        return <TxtIcon />;
      case 'url':
        return <UrlIcon />;
    }
  };

  if (!open) return null;

  return (
    <>
      <Backdrop onClick={onClose} />
      <FloatingBox>
        <StyledTitle>
          <Text type={'label1normal'} weight="bold">
            {t('selectFile')}
          </Text>
        </StyledTitle>
        <Info>
          <Text type={'label1normal'}>{t('sourceInformation')}</Text>
        </Info>
        <UploadBox onClick={triggerFileInput}>
          <input type="file" id="fileInput" ref={fileInputRef} style={{ display: 'none' }} onChange={uploadFile} />
          {loading && (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          )}
          {!loading && (
            <>
              <FileImageWrapper src={images} />
              <Text type={'label1normal'}>{t('clickhere')}</Text>
            </>
          )}
        </UploadBox>
        <UrlInputContainer>
          {!urlLoading && (
            <UrlInput type="text" value={urlInput} onChange={handleUrlChange} placeholder={t('enterUrl')} />
          )}
          {urlLoading && (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          )}
          <Button
            onClickAction={handleUrlSubmit}
            buttonType="transparent-grey"
            text={t('addUrl')}
            textColor="black"
            textType={'label2'}
            size={'small'}
            weight="bold"
            padding="7px var(--Spacing-m, 14px);"
          />
        </UrlInputContainer>
        <SourceBox>
          <Text type={'label1normal'} weight="bold">
            {t('addedSources')}
          </Text>
          {uploads.map((upload, index) => (
            <Source key={index}>
              {getIconByFileType(upload.file_type)}
              <Text type={'label1normal'} weight="bold">
                {upload.filename.slice(0, 30)}
              </Text>
            </Source>
          ))}
        </SourceBox>
        <ButtonsRow>
          <Button
            onClickAction={deleteUploads}
            buttonType="transparent-grey"
            text={t('deleteUploads')}
            textColor="black"
            textType={'label2'}
            padding="var(--Radius-Sharp, 0px) var(--Spacing-ml, 20px)"
            size={'small'}
            weight="bold"
          />
          <Button
            onClickAction={onClose}
            buttonType="blue-white"
            text={t('close')}
            textColor="white"
            textType={'label2'}
            padding="var(--Radius-Sharp, 0px) var(--Spacing-ml, 20px)"
            size={'small'}
            weight="bold"
          />
        </ButtonsRow>
      </FloatingBox>
    </>
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: ${(props) => props.theme.colors.backdrop};
`;

const FloatingBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  min-width: 600px;
  width: 50%;
  padding: var(--Spacing-ml, 20px);
  gap: var(--Spacing-ml, 20px);

  @media only screen and ${deviceBreakpoints.mobile} {
    min-width: unset;
    width: 336px;
  }
`;

const StyledTitle = styled.div`
  width: 100%;
  text-align: left;
  border-radius: 4px;
`;

const Info = styled.div`
  width: 100%;
  text-align: left;
`;

const UploadBox = styled.div`
  display: flex;
  padding: var(--Spacing-ml, 20px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid ${(props) => props.theme.colors.borderGrey};
  border-radius: 12px;
`;

const FileImageWrapper = styled.img`
  width: 160px;
  height: 36px;
`;

const SourceBox = styled.div`
  border-radius: 12px;
  background: ${(props) => props.theme.colors.lightGrey};
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: var(--general-beta-08, 8px);
  align-self: stretch;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const UrlInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
`;

const Source = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  text-align: left;
  width: 100%;
`;

const UrlInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  background: ${(props) => props.theme.colors.entry};
`;

const ButtonsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export default SourceDialog;

export enum LoadingStatus {
  idle = 'idle',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export interface RequestErrors {
  message: string;
  statusCode: number;
  error: string;
}

export interface ResponseUnionType<T> {
  statusCode: number;
  message: string;
  data: T;
}

export interface Conversation {
  role: string;
  content: string;
}

export interface TranslationType {
  Desktop: string[];
  Mobile: string[];
}

export type Diarization = {
  speakerId: number;
  speakerName: string;
  words: string;
  duration: number;
  start: number;
  end: number;
  key: string;
  isBookmarked: boolean;
};

export type RealtimeSTTData = { words: string; speaker: number; isBookmarked?: boolean; start: number; end: number };

export type BookmarksListPlaybackMarkersType = Array<{ id: number; start: number; end: number }>;

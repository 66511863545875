import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Text } from '../Text';

interface BubbleInputProps {
  title: number;
  text: string;
  start: number;
  end: number;
  translation: boolean;
  highlighted: boolean;
}

const Bubble = forwardRef<HTMLDivElement, BubbleInputProps>(
  ({ title, text, start, end, translation, highlighted }, ref) => (
    <BubbleContainer ref={ref} translation={translation}>
      <TitleContainer translation={translation}>
        <Text type="label1normal" textColor={translation ? 'white' : 'black'}>
          Speaker {title + 1}
        </Text>
      </TitleContainer>
      <TextContainer>
        <Text type="label1normal" textColor={'black'}>
          {Math.round(start)}
          {'s --> '}
          {Math.round(end) + 's'}
        </Text>
      </TextContainer>
      <TextContainer>
        <Text type="body1reading" textColor={highlighted ? 'red' : 'grey'}>
          {text}
        </Text>
      </TextContainer>
    </BubbleContainer>
  ),
);

Bubble.displayName = 'Bubble';

const BubbleContainer = styled.div<{ translation: boolean }>`
  word-wrap: break-word;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const TitleContainer = styled.div<{ translation: boolean }>`
  background-color: ${(props) => (props.translation ? '#3b3d45' : '#f1f1f1')};
  display: flex;
  padding: 4px var(--general-beta-08, 8px);
  justify-content: center;
  align-items: center;
  border-radius: var(--general-beta-08, 8px);
`;

const TextContainer = styled.div`
  display: flex;
  padding: var(--general-beta-08, 8px);
  align-items: flex-start;
  align-self: stretch;
`;

export default Bubble;

import { AxiosResponse } from 'axios';
import { sttHttp } from 'services/http';

import { Diarization, ResponseUnionType } from '../types';

export interface DiarizationHistory {
  sessionId: string;
  date: string;
  diarization: Diarization[];
  translation: Diarization[];
  filename: string;
}

export interface DiarizedResponseDto {
  diarization: Diarization[];
  translation: Diarization[];
}

export interface DiarizeRequestDto {
  formData: FormData;
  sessionId: string;
  filename: string;
  sourceLanguage: string;
  translateLanguage: string;
}

export interface GetDiarizationHistoryResDto {
  diarizations: DiarizationHistory[];
}

export interface DeleteResponseDto {
  success: boolean;
}

export interface TranslationResponseDto {
  translation: string;
}

export interface TranslationRequestDto {
  originalText: string;
  sourceLanguage: string;
  targetLanguage: string;
}

export const AnalyzeApi = {
  requestDiarization: async (
    data: DiarizeRequestDto,
  ): Promise<AxiosResponse<ResponseUnionType<DiarizedResponseDto>>> => {
    return sttHttp.post(
      `analyze?sourceLanguage=${data.sourceLanguage}&translateLanguage=${data.translateLanguage}&sessionId=${data.sessionId}&filename=${data.filename}`,
      data.formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  },
  getDiarizations: async (): Promise<AxiosResponse<ResponseUnionType<GetDiarizationHistoryResDto>>> => {
    return sttHttp.get('analyze/diarizations');
  },
  deleteDiarization: async (session_id: string): Promise<AxiosResponse<DeleteResponseDto>> => {
    return sttHttp.delete(`analyze?sessionId=${session_id}`);
  },
  getTranslation: async (
    data: TranslationRequestDto,
  ): Promise<AxiosResponse<ResponseUnionType<TranslationResponseDto>>> => {
    return sttHttp.post('/analyze/translate', data);
  },
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddMessageToChatDto,
  AddMessageToChatResDto,
  GetAllSummaryResDto,
  GetSummaryResDto,
  GetUploadsResDto,
  GetUserChatsResDto,
  UpdateChatNameDto,
  UploadFilesDto,
  UploadFilesResponseDto,
  UploadURLDto,
  UploadURLResponseDto,
  UserConversationHistory,
} from 'api/chat.api';
import { ExtraParamsThunkType } from 'store/types';
import { RequestErrors } from 'types';
import { v4 as uuidv4 } from 'uuid';

export const createNewChatSessionAsync = createAsyncThunk('chat/createNewChatSession', async () => {
  const newSessionId = uuidv4();
  return newSessionId;
});

export const uploadRAGFiles = createAsyncThunk<
  UploadFilesResponseDto,
  UploadFilesDto,
  ExtraParamsThunkType<RequestErrors>
>('chat/upload', async (requestData, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.ChatApi.uploadFiles(requestData);
    return data.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const uploadRAGURL = createAsyncThunk<UploadURLResponseDto, UploadURLDto, ExtraParamsThunkType<RequestErrors>>(
  'chat/upload_url',
  async (requestData, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.ChatApi.uploadURL(requestData);
      return data.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const addChatToConversationAsync = createAsyncThunk<
  AddMessageToChatResDto,
  AddMessageToChatDto,
  ExtraParamsThunkType<RequestErrors>
>('chat/generate', async (payload, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.ChatApi.addMessageToChat(payload);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getUserChatsAsync = createAsyncThunk<GetUserChatsResDto, void, ExtraParamsThunkType<RequestErrors>>(
  'chat/user_chats',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.ChatApi.getChats();
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const getChatBySessionIdAsync = createAsyncThunk<
  UserConversationHistory,
  string,
  ExtraParamsThunkType<RequestErrors>
>('chat/session_chat', async (session_id, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.ChatApi.getSessionChat(session_id);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getUploadsAsync = createAsyncThunk<GetUploadsResDto, void, ExtraParamsThunkType<RequestErrors>>(
  'chat/uploads',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.ChatApi.getUploads();
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSummaryAsync = createAsyncThunk<GetSummaryResDto, string, ExtraParamsThunkType<RequestErrors>>(
  'chat/summary',
  async (upload_id, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.ChatApi.getSummary(upload_id);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAllSummaryAsync = createAsyncThunk<GetAllSummaryResDto, void, ExtraParamsThunkType<RequestErrors>>(
  'chat/all_summary',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.ChatApi.getSummarys();
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteUploadsAsync = createAsyncThunk<void, void, ExtraParamsThunkType<RequestErrors>>(
  'chat/delete_upload',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.ChatApi.deleteUploads();
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateNameAsync = createAsyncThunk<void, UpdateChatNameDto, ExtraParamsThunkType<RequestErrors>>(
  'chat/update_name',
  async (payload, { extra: { api }, rejectWithValue }) => {
    try {
      await api.ChatApi.updateName(payload);
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteSessionChatAsync = createAsyncThunk<void, string, ExtraParamsThunkType<RequestErrors>>(
  'chat/delete_upload',
  async (session_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.ChatApi.deleteChat(session_id);
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetAllSpeakersResDto, GetSpeakerModelResDto } from '../../api/speakers.api';
import { ExtraParamsThunkType } from '../../store/types';
import { RequestErrors } from '../../types';

export const getAllSpeakersAsync = createAsyncThunk<GetAllSpeakersResDto, void, ExtraParamsThunkType<RequestErrors>>(
  'speakers/getAll',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.SpeakersApi.getAllSpeakers();
      return data.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSpeakerModelAsync = createAsyncThunk<
  GetSpeakerModelResDto,
  string,
  ExtraParamsThunkType<RequestErrors>
>('speakers/speakerModel', async (speakerModel: string, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.SpeakersApi.getSpeakerModel(speakerModel);
    return data.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deleteSpeakerAsync = createAsyncThunk<string, string, ExtraParamsThunkType<RequestErrors>>(
  'speakers/delete',
  async (speakerName: string, { extra: { api }, rejectWithValue }) => {
    try {
      await api.SpeakersApi.deleteSpeaker(speakerName);
      return speakerName;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

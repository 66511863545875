import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DiarizedResponseDto,
  DiarizeRequestDto,
  GetDiarizationHistoryResDto,
  TranslationRequestDto,
  TranslationResponseDto,
} from 'api/analyze.api';
import { ExtraParamsThunkType } from 'store/types';
import { RequestErrors } from 'types';
import { v4 as uuidv4 } from 'uuid';

export const createNewSessionAsync = createAsyncThunk('analyze/createNewChatSession', async () => {
  const newSessionId = uuidv4();
  return newSessionId;
});

export const getDiarizedFileAsync = createAsyncThunk<
  DiarizedResponseDto,
  DiarizeRequestDto,
  ExtraParamsThunkType<RequestErrors>
>('analyze/diarization', async (requestData, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.AnalyzeApi.requestDiarization(requestData);
    return data.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getAllDiarizationsAsync = createAsyncThunk<
  GetDiarizationHistoryResDto,
  void,
  ExtraParamsThunkType<RequestErrors>
>('analyze/diarizations', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.AnalyzeApi.getDiarizations();
    return data.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deleteSessionAsync = createAsyncThunk<void, string, ExtraParamsThunkType<RequestErrors>>(
  'analyze/delete_analysis',
  async (session_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.AnalyzeApi.deleteDiarization(session_id);
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const getTranslationAsync = createAsyncThunk<
  TranslationResponseDto,
  TranslationRequestDto,
  ExtraParamsThunkType<RequestErrors>
>('analyze/translate', async (requestData: TranslationRequestDto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.AnalyzeApi.getTranslation(requestData);
    return data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

import { Tooltip } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { DatabaseDialog } from 'components/atoms';
import { SourceDialog } from 'components/atoms/SourceDialog';
import { actions } from 'features/chat';
import { selectUploadFiles } from 'features/chat/selector';
import { createNewChatSessionAsync, uploadRAGFiles, uploadRAGURL } from 'features/chat/thunks';
import DatabaseIcon from 'icons/Database';
import HelpIcon from 'icons/Help';
import ModelIcon from 'icons/Model';
import PlusIcon from 'icons/Plus';
import SendIcon from 'icons/Send';
import SourceIcon from 'icons/Source';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { notifyErr, notifySuc } from 'utils/notification';

import { HelpDialog } from '../HelpDialog';
import { ModelDialog } from '../ModelDialog';

interface ChatInputProps {
  message: string;
  setMessage: (message: string) => void;
  onSendMessage: () => void;
  selectedModel: string;
  setSelectedModel: (model: string) => void;
  selectedDatabase: string;
  setSelectedDatabase: (model: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
  message,
  setMessage,
  onSendMessage,
  selectedModel,
  setSelectedModel,
  selectedDatabase,
  setSelectedDatabase,
}) => {
  const [isSourceDialogOpen, setSourceDialogOpen] = useState(false);
  const [isModelDialogOpen, setModelDialogOpen] = useState(false);
  const [isDatabaseDialogOpen, setDatabaseDialogOpen] = useState(false);
  const [isHelpDialogOpen, setHelpDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('gpt');
  const uploadFiles = useSelector(selectUploadFiles);
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);

  const handleOpenSourceDialog = () => {
    setSourceDialogOpen(true);
    setIsUploadSuccessful(false);
  };

  const handleCloseSourceDialog = () => {
    setSourceDialogOpen(false);
  };

  const handleOpenModelDialog = () => {
    setModelDialogOpen(true);
  };

  const handleCloseModelDialog = () => {
    setModelDialogOpen(false);
    dispatch(createNewChatSessionAsync());
    window.location.reload();
  };

  const handleOpenDatabaseDialog = () => {
    setDatabaseDialogOpen(true);
  };

  const handleCloseDatabaseDialog = () => {
    setDatabaseDialogOpen(false);
    dispatch(createNewChatSessionAsync());
    window.location.reload();
  };

  const handleOpenHelpDialog = () => {
    setHelpDialogOpen(true);
  };

  const handleCloseHelpDialog = () => {
    setHelpDialogOpen(false);
  };

  const handleKeyPress = (event: { key: string; preventDefault: () => void }) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSendMessage();
    }
  };

  const handleNewChatClick = () => {
    dispatch(createNewChatSessionAsync());
  };

  const fetchBlob = async (fileLinks: Array<File>) => {
    console.log(`Uploading ${fileLinks.length} files`);
    const formData = new FormData();
    for (const file of fileLinks) {
      const blobLink = URL.createObjectURL(file);
      const response = await fetch(blobLink);
      const responseBlob = await response.blob();
      const temp = new File([responseBlob], file.name, {
        type: file.type,
      });
      dispatch(actions.uploadFiles([...uploadFiles, file.name]));
      formData.append('file', temp);
      console.log('Adding ', temp.name);
    }
    dispatch(uploadRAGFiles({ formData }))
      .then(unwrapResult)
      .then(() => {
        notifySuc(t('fileUploadSuccess'));
        setIsUploadSuccessful(true);
        handleCloseSourceDialog();
      })
      .catch(() => {
        notifyErr(t('fileUploadError'), '10');
        setIsUploadSuccessful(false);
      });
  };

  const onLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;

    const fileLinks: File[] = Array.from(files);
    if (fileLinks.length > 10) {
      notifyErr(t('maxUploadFiles'), '10');
      return;
    }

    const FILESIZE_LIMIT = 40000000;
    const acceptedTypes = ['application/pdf', 'application/zip', 'text/plain', 'application/x-zip-compressed'];

    for (const file of fileLinks) {
      if (file.size > FILESIZE_LIMIT) {
        notifyErr(t('fileUploadErrorFileTooBig'), '10');
        return;
      }
      if (!acceptedTypes.includes(file.type)) {
        notifyErr(t('incorrectFileError'), '10');
        return;
      }
    }

    fetchBlob(fileLinks);
  };

  const onLoadURL = async (url: string) => {
    dispatch(actions.uploadURL(url));

    dispatch(uploadRAGURL({ url }))
      .then(unwrapResult)
      .then(() => {
        notifySuc(t('fileUploadSuccess'));
        setIsUploadSuccessful(true);
        setSelectedDatabase('noDatabase');
        setSelectedModel('llama3-70b-8192');
        handleCloseSourceDialog();
      })
      .catch(() => {
        notifyErr(t('fileUploadError'), '10');
        setIsUploadSuccessful(false);
      });
  };

  return (
    <ChatInputContainer>
      <Tooltip title={t('source')} arrow>
        <IconWrapper onClick={handleOpenSourceDialog}>
          <SourceIcon />
        </IconWrapper>
      </Tooltip>
      <Tooltip title={t('selectModel')} arrow>
        <IconWrapper onClick={handleOpenModelDialog}>
          <ModelIcon />
        </IconWrapper>
      </Tooltip>
      <Tooltip title={t('selectDatabase')} arrow>
        <IconWrapper onClick={handleOpenDatabaseDialog}>
          <DatabaseIcon />
        </IconWrapper>
      </Tooltip>
      <MessageInputContainer>
        <MessageInput
          type="text"
          placeholder={t('messagePlaceholder')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <SendIconWrapper onClick={onSendMessage}>
          <SendIcon filled={message.length > 0} />
        </SendIconWrapper>
      </MessageInputContainer>
      <Tooltip title={t('help')} arrow>
        <IconWrapper onClick={handleOpenHelpDialog}>
          <HelpIcon />
        </IconWrapper>
      </Tooltip>
      <MobileActionsContainer>
        <MobileWrapper onClick={handleNewChatClick}>
          <PlusIcon />
        </MobileWrapper>
        <MobileWrapper onClick={handleOpenSourceDialog}>
          <SourceIcon />
        </MobileWrapper>
        <MobileWrapper onClick={handleOpenModelDialog}>
          <ModelIcon />
        </MobileWrapper>
        <MobileWrapper onClick={handleOpenHelpDialog}>
          <HelpIcon />
        </MobileWrapper>
      </MobileActionsContainer>
      <SourceDialog
        open={isSourceDialogOpen}
        onClose={handleCloseSourceDialog}
        onLoadFile={onLoadFile}
        onLoadURL={onLoadURL}
        uploadSuccess={isUploadSuccessful}
      />
      <ModelDialog
        open={isModelDialogOpen}
        onClose={handleCloseModelDialog}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />
      <DatabaseDialog
        open={isDatabaseDialogOpen}
        onClose={handleCloseDatabaseDialog}
        selectedDatabase={selectedDatabase}
        setSelectedDatabase={setSelectedDatabase}
      />
      <HelpDialog open={isHelpDialogOpen} onClose={handleCloseHelpDialog} />
    </ChatInputContainer>
  );
};

const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 20px;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  justify-content: center;
  gap: var(--Spacing-ml, 10px);
  position: sticky;
  bottom: 0;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    padding: 10px;
    min-width: unset;
    width: 100%;
    gap: 10px;
    border-radius: 10px;
  }
`;

const MessageInputContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  border-radius: 99px;
  min-width: 100px;
  background: ${(props) => props.theme.colors.shade};

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  gap: 5px;
  padding: 8px 12px;
  border-radius: 8px;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

const MobileActionsContainer = styled.div`
  display: none;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
`;

const MobileWrapper = styled.div`
  display: none;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
`;

const MessageInput = styled.input`
  flex-grow: 1;
  height: 56px;
  padding: 16px;
  border: none;
  border-radius: 99px;
  padding-right: 50px;
  background: transparent;
  outline: none;

  @media only screen and ${deviceBreakpoints.mobile} {
    height: 48px;
    padding: 12px;
  }
`;

const SendIconWrapper = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: var(--Radius-xl, 36px);
  height: var(--Radius-xl, 36px);
  flex-shrink: 0;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 32px;
    height: 32px;
  }
`;

export default ChatInput;

import { useEffect, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';

export default function useMediaRecorder() {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const {
    clearBlobUrl,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewAudioStream,
    pauseRecording,
    resumeRecording,
    status,
  } = useReactMediaRecorder({
    audio: true,
    blobPropertyBag: {
      type: 'audio/wav',
    },
  });

  useEffect(() => {
    if (previewAudioStream && previewAudioStream.active && !mediaStream) {
      setMediaStream(previewAudioStream);
    }
  }, [previewAudioStream]);

  useEffect(() => {
    if (previewAudioStream?.active) {
      setMediaStream(previewAudioStream);
    } else {
      setMediaStream(null);
    }
  }, [mediaBlobUrl]);

  return {
    startRecording,
    pauseRecording,
    stopRecording,
    clearBlobUrl,
    mediaStream,
    mediaBlobUrl,
    resumeRecording,
    status,
  };
}

import { Header } from 'components/organisms';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

const AuthorizationLayout = () => {
  return (
    <Root>
      <Header />
      <Main>
        <Outlet />
      </Main>
    </Root>
  );
};

const Root = styled.div``;

const Main = styled.main`
  overflow-x: hidden;
  position: relative;
  padding: '20px';

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
  }
`;

export default AuthorizationLayout;

import { GoogleLogin } from '@react-oauth/google';
import { unwrapResult } from '@reduxjs/toolkit';
import { SignInResDto } from 'api/auth.api';
import Or from 'assets/images/Or.png';
import { Button } from 'components/atoms';
import InputField from 'components/atoms/InputField/InputField';
import { Text } from 'components/atoms/Text';
import { AuthorizedRoutes, UnauthorizedRoutes } from 'constants/routes';
import { selectLoadingStatus } from 'features/auth/selectors';
import { googleSignInAsync, signInAsync } from 'features/auth/thunks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { LoadingStatus } from 'types';
import { notifyErr } from 'utils/notification';

interface FormValue {
  email: string;
  password: string;
}

const LoginForm = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loadingStatus = useSelector(selectLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const { control, handleSubmit } = useForm<FormValue>({
    defaultValues: { email: '', password: '' },
    mode: 'onChange',
  });

  const onSubmit = async (data: FormValue) => {
    await dispatch(signInAsync(data))
      .then((response) => {
        unwrapResult(response);
        return response.payload;
      })
      .then((response) => {
        if ((response as SignInResDto).data.isVerified) {
          navigate(AuthorizedRoutes.LANDING_PAGE);
        } else {
          navigate(UnauthorizedRoutes.VERIFICATION);
        }
      })
      .catch((error) => {
        if (error?.statusCode && error.statusCode === 401) {
          notifyErr(t('errorMessageAuthorizationIncorrectData'), '1');
        } else {
          notifyErr(t('errorMessageAuthorization'), '2');
        }
      });
  };

  const handleGoogleLoginSuccess = async (creds: any) => {
    // Send the token to your backend for verification
    await dispatch(googleSignInAsync(creds))
      .then((response) => {
        unwrapResult(response);
        return response.payload;
      })
      .then((response) => {
        if ((response as SignInResDto).data.isVerified) {
          navigate(AuthorizedRoutes.LANDING_PAGE);
        } else {
          navigate(UnauthorizedRoutes.VERIFICATION);
        }
      })
      .catch((error) => {
        if (error?.statusCode && error.statusCode === 401) {
          notifyErr(t('errorMessageAuthorizationIncorrectData'), '1');
        } else {
          notifyErr(t('errorMessageAuthorization'), '2');
        }
      });
  };

  return (
    <Root>
      <Text type="title1">{t('login')}</Text>
      <LoginWrapper onSubmit={handleSubmit(onSubmit)}>
        <InputField
          control={control}
          name="email"
          rules={{ required: { value: true, message: 'Required field' } }}
          label={t('email')}
          placeholder="E-mail@example.com"
        />
        <InputField
          type="password"
          rules={{ required: { value: true, message: 'Required field' } }}
          control={control}
          name="password"
          label={t('password')}
          placeholder={t('password')}
        />
        <Button
          text={t('login')}
          disabled={isLoading}
          buttonType={'blue-white'}
          textType="body1normal"
          textColor={'white'}
          weight={'bold'}
          size="large"
          width="100%"
        />
      </LoginWrapper>
      <AlternativeWrapper>
        <OrWrapper src={Or} />
        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={() => {
            notifyErr(t('errorMessageAuthorization'), '2');
          }}
        />
        <Button
          text={t('signUp')}
          disabled={isLoading}
          buttonType={'white-blue'}
          textType="body1normal"
          textColor={'blue'}
          weight={'bold'}
          size="large"
          width="100%"
          onClickAction={() => navigate(UnauthorizedRoutes.SIGN_UP)}
        />
      </AlternativeWrapper>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 416px;
  margin: 0 auto;
  align-items: center;
  @media (max-width: 765px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const LoginWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 10px;
  width: 100%;
  gap: 20px;
`;

const OrWrapper = styled.img`
  width: 416px;
  height: 18px;
`;

const AlternativeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 60px;
  gap: 20px;
`;

export default LoginForm;

import Axios, { AxiosInstance } from 'axios';
import { authServerString, gptServerString, sidServerString, sttServerString } from 'config';
import { actions } from 'features/auth';
import { store } from 'store';

export class Http {
  constructor(private readonly _axios: AxiosInstance) {}

  setAuthorizationHeader(token: string): void {
    this._axios.defaults.headers.Authorization = `Bearer ${token}`;
  }

  unsetAuthorizationHeader(): void {
    delete this._axios.defaults.headers.Authorization;
  }

  get get() {
    return this._axios.get;
  }

  get post() {
    return this._axios.post;
  }

  get put() {
    return this._axios.put;
  }

  get patch() {
    return this._axios.patch;
  }

  get delete() {
    return this._axios.delete;
  }

  get axios(): AxiosInstance {
    return this._axios;
  }
}

export const sttHttp = new Http(
  Axios.create({
    baseURL: sttServerString,
  }),
);

export const authHttp = new Http(
  Axios.create({
    baseURL: authServerString,
  }),
);

export const gptHttp = new Http(
  Axios.create({
    baseURL: gptServerString,
  }),
);

export const sidHttp = new Http(
  Axios.create({
    baseURL: sidServerString,
  }),
);

sttHttp.axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch(actions.signOut());
    }

    return Promise.reject(error);
  },
);

gptHttp.axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch(actions.signOut());
    }

    return Promise.reject(error);
  },
);

sidHttp.axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch(actions.signOut());
    }

    return Promise.reject(error);
  },
);

import { AuthorizedRoutes } from 'constants/routes';
import { MainLayout } from 'layouts';
import { GPTPage } from 'pages/GPTPage';
import { LandingPage } from 'pages/LandingPage';
import { STTPage } from 'pages/STTPage';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path: AuthorizedRoutes.LANDING_PAGE,
        element: <LandingPage />,
      },
      {
        path: AuthorizedRoutes.REAL_GPT,
        element: <GPTPage />,
      },
      {
        path: AuthorizedRoutes.REAL_STT,
        element: <STTPage />,
      },
      { path: '*', element: <Navigate to={AuthorizedRoutes.LANDING_PAGE} /> },
    ],
  },
]);

const AuthorizedRouting = () => {
  return <RouterProvider router={router} />;
};

export default AuthorizedRouting;

import React from 'react';

const RealSTTIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill="#E8FDF8" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        x="12"
        y="12"
        transform="translate(-12 -12)">
        <path
          d="M17 2H7C5.9 2 5 2.9 5 4V20C5 21.1 5.9 21.99 7 21.99L17 22C18.1 22 19 21.1 19 20V4C19 2.9 18.1 2 17 2ZM12 4C13.1 4 14 4.9 14 6C14 7.1 13.1 8 12 8C10.89 8 10 7.1 10 6C10 4.9 10.89 4 12 4ZM12 20C9.24 20 7 17.76 7 15C7 12.24 9.24 10 12 10C14.76 10 17 12.24 17 15C17 17.76 14.76 20 12 20ZM12 12C10.34 12 9 13.34 9 15C9 16.66 10.34 18 12 18C13.66 18 15 16.66 15 15C15 13.34 13.66 12 12 12Z"
          fill="#27DDAD"
        />
      </svg>
    </svg>
  );
};

export default RealSTTIcon;

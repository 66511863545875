import React from 'react';

const ModelIcon: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.5 14.147C15.5 16.147 13 17.647 13 19.147H11C11 17.647 8.5 16.147 8.5 14.147C8.5 12.217 10.07 10.647 12 10.647C13.93 10.647 15.5 12.217 15.5 14.147ZM13 20.147H11V20.647C11 21.197 11.45 21.647 12 21.647C12.55 21.647 13 21.197 13 20.647V20.147ZM19 13.647C19 15.037 18.59 16.337 17.88 17.427C17.63 17.817 17.69 18.337 18.02 18.667C18.46 19.107 19.22 19.047 19.56 18.517C20.47 17.117 21 15.447 21 13.647C21 11.287 20.09 9.137 18.6 7.527C18.21 7.107 17.55 7.097 17.15 7.497C16.77 7.877 16.77 8.487 17.13 8.887C18.29 10.137 19 11.807 19 13.647ZM15.65 5.297L12.86 2.507C12.54 2.187 12 2.407 12 2.857V4.647C7.03 4.647 3 8.677 3 13.647C3 15.447 3.53 17.117 4.44 18.527C4.78 19.057 5.54 19.117 5.98 18.677C6.31 18.347 6.37 17.837 6.12 17.447C4.73 15.297 4.48 12.347 6.25 9.447C7.45 7.497 9.71 6.457 12 6.647V8.437C12 8.887 12.54 9.107 12.85 8.787L15.64 5.997C15.84 5.807 15.84 5.487 15.65 5.297Z"
        fill="#2E2F33"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default ModelIcon;

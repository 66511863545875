import logoBig from 'assets/images/LogoBig.png';
import logoFooter from 'assets/images/LogoFooter.png';
import logoMobile from 'assets/images/LogoMobile.png';
import React from 'react';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../Theme';

interface LogoProps {
  marginLeft: string;
  size?: 'small' | 'footer' | 'default';
  className?: string;
  onClick?: () => void;
}

const Logo: React.FC<LogoProps> = ({ className, size, marginLeft, onClick }) => {
  let imgSrc = logoBig;
  let width = 240;
  if (size === 'small') {
    imgSrc = logoMobile;
    width = 140;
  } else if (size === 'footer') {
    imgSrc = logoFooter;
    width = 233;
  }

  return (
    <LogoWrapper className={className} margin_left={marginLeft}>
      <IconWrapper onClick={onClick}>
        <img src={imgSrc} width={width} />
      </IconWrapper>
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div<{ margin_left: string }>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.margin_left};

  @media only screen and ${deviceBreakpoints.mobile} {
    justify-self: center;
    margin-left: ${(props) => props.margin_left};
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

export default Logo;

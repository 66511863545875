import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '../../types';
import { deleteSpeakerAsync, getAllSpeakersAsync, getSpeakerModelAsync } from './thunks';
import { SpeakersStore } from './types';

const initialState: SpeakersStore = {
  speakers: undefined,
  verificationSpeaker: undefined,
  verificationSpeakerModel: undefined,
  verificationSpeakerResult: [],
  loadingStatus: LoadingStatus.idle,
  error: null,
};

const speakersSlice = createSlice({
  name: 'speakers',
  initialState,
  reducers: {
    setVerificationSpeaker: (state, action: PayloadAction<string>) => {
      return { ...state, verificationSpeaker: action.payload };
    },
    unsetVerificationSpeaker: (state) => {
      return { ...state, verificationSpeaker: undefined };
    },
    setVerificationSpeakerResult: (state, action: PayloadAction<number>) => {
      return { ...state, verificationSpeakerResult: [...state.verificationSpeakerResult, action.payload] };
    },
    unsetVerificationSpeakerResult: (state) => {
      return { ...state, verificationSpeakerResult: [] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSpeakersAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getAllSpeakersAsync.fulfilled, (state, { payload }) => {
      return { ...state, speakers: payload, loadingStatus: LoadingStatus.idle };
    });
    builder.addCase(getAllSpeakersAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });

    builder.addCase(getSpeakerModelAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getSpeakerModelAsync.fulfilled, (state, { payload }) => {
      return { ...state, verificationSpeakerModel: payload.audioFileName, loadingStatus: LoadingStatus.idle };
    });
    builder.addCase(getSpeakerModelAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });

    builder.addCase(deleteSpeakerAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(deleteSpeakerAsync.fulfilled, (state, { payload }) => {
      const newSpeakersList = state.speakers?.filter((speaker) => speaker.name !== payload);

      return {
        ...state,
        loadingStatus: LoadingStatus.idle,
        speakers: newSpeakersList,
      };
    });
    builder.addCase(deleteSpeakerAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
  },
});

export const actions = {
  ...speakersSlice.actions,
};
export const { reducer } = speakersSlice;

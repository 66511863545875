import backgroundImage from 'assets/images/window.png';
import { gaMeasurementId } from 'config';
import { UnauthorizedRoutes } from 'constants/routes';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import RequestVerificationLinkForm from './RequestVerificationLinkForm';
import VerificationPage from './VerificationPage';

ReactGA.initialize(gaMeasurementId);
const AuthorizationPage = () => {
  const location = useLocation();
  ReactGA.send({ hitType: 'pageview', page: '/auth/sign-in', title: 'Demo Login' });

  return (
    <Root>
      <ContentWrapper>
        <BackgroundImage src={backgroundImage} />
        <FormWrapper>
          {location.pathname === UnauthorizedRoutes.SIGN_IN && <LoginForm />}
          {location.pathname === UnauthorizedRoutes.SIGN_UP && <RegistrationForm />}
          {location.pathname === UnauthorizedRoutes.VERIFICATION && <VerificationPage />}
          {location.pathname === UnauthorizedRoutes.REQUEST_VERIFICATION_LINK && <RequestVerificationLinkForm />}
        </FormWrapper>
      </ContentWrapper>
      <Footer>© 2024 DARWIN INTELLIGENCE. All rights reserved</Footer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme: { colors } }) => colors.lightGrey};
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 1152px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;
  box-sizing: border-box;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    max-width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    flex-grow: 0;
  }
`;

const Footer = styled.footer`
  text-align: center;
  padding: 20px;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  margin-top: auto;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ theme: { colors } }) => colors.white};
  padding: 60px 80px var(--Radius-Sharp, 0px) 80px;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  align-self: stretch;
  max-width: 576px;

  @media only screen and ${deviceBreakpoints.mobile} {
    border-radius: 0;
    padding: 24px 30px;
    flex-grow: 0;
  }
`;

const BackgroundImage = styled.img`
  flex: 1;
  max-width: 576px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

export default AuthorizationPage;

import React, { ButtonHTMLAttributes, ReactElement, useState } from 'react';
import styled, { css, RuleSet } from 'styled-components';

import Text from '../Text/Text';

interface ButtonStyleProps {
  size?: 'large' | 'medium' | 'small';
  disabled?: boolean;
  display?: 'none' | 'flex';
  textColor?: string;
  padding?: string;
  width?: string; // Add width prop
}

type ButtonType = 'blue-white' | 'transparent-grey' | 'white-blue' | 'transparent-white';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: ButtonType;
  textType: string;
  text: string;
  icon?: ReactElement;
  weight: 'regular' | 'medium' | 'bold';
  onClickAction?: () => void;
  padding?: string;
  className?: string;
  pressedTextColor?: string;
}

const Button: React.FC<ButtonProps & ButtonStyleProps> = ({
  buttonType,
  text,
  icon,
  textType,
  size = 'medium',
  weight = 'regular',
  textColor,
  padding = '0px',
  onClickAction,
  className,
  pressedTextColor,
  width,
  ...other
}) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => setIsPressed(true);
  const handleMouseUp = () => setIsPressed(false);
  const handleMouseLeave = () => setIsPressed(false);

  return (
    <BaseButton
      buttonType={buttonType}
      size={size}
      width={width}
      onClick={onClickAction}
      className={`${className} ${isPressed ? 'pressed' : ''}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      disabled={other.disabled}>
      {icon}
      <Text
        padding={padding}
        type={textType}
        textColor={isPressed && pressedTextColor ? pressedTextColor : textColor}
        weight={weight}>
        {text}
      </Text>
    </BaseButton>
  );
};

const sizeStyles = {
  large: css`
    height: 52px;
    padding: var(--Spacing-sm, 12px) var(--Spacing-l, 28px);
  `,
  medium: css`
    height: 44px;
    padding: 9px var(--Spacing-ml, 20px);
  `,
  small: css`
    height: var(--Spacing-xl, 40px);
    padding: 7px var(--Spacing-m, 14px);
  `,
};

const buttonTypeStyles: Record<ButtonType, RuleSet<object>> = {
  'blue-white': css`
    background: var(--Secondary-Secondary, #1a2b7c);
    &:hover {
      background: #2b3c8a;
    }
    &:focus {
      background: #313a65;
    }
    &.pressed {
      background: #19276a;
    }
  `,
  'transparent-grey': css`
    border: var(--Radius-Sharp, 1px) solid var(--DW-Semantic-Line-Normal-Strong, rgba(112, 115, 124, 0.52));
    &:hover {
      background: var(--DW-Semantic-Fill-Strong, #e8ecf0);
    }
    &:focus {
      background: #f1f1f1;
    }
    &:pressed {
      background: var(--Neutrals-Neutrals700, #3b3d45);
    }
  `,
  'white-blue': css`
    background: var(--DW-Semantic-Static-White, #fffff);
    &:hover {
      background: #d3d9e0;
    }
    &:focus {
      background: #c6cdd6;
    }
    &.pressed {
      background: #989fa8;
    }
  `,
  'transparent-white': css`
    border: 1px solid var(--Base-Base-White, #ffffff);
    &:hover {
      border: var(--Radius-Sharp, 1px) solid var(--DW-Semantic-Line-Solid-Neutral, #eaebec);
      background: #d3d9e0;
    }
  `,
};

const BaseButton = styled.button<ButtonStyleProps & { buttonType: ButtonType }>`
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || 'fit-content'};
  white-space: nowrap;
  background: none;
  border: var(--Radius-Sharp, 1px) solid var(--Secondary-Secondary, #1a2b7c);
  opacity: ${(props) => (props.disabled ? '50%' : '100%')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-position: right 20%;
  position: relative;
  overflow: hidden;
  outline: none;
  transform: translateZ(0);

  ${({ size = 'medium' }) => sizeStyles[size]}
  ${({ buttonType }) => buttonTypeStyles[buttonType]}
`;

export default Button;

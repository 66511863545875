import { UnauthorizedRoutes } from 'constants/routes';
import { AuthorizationLayout } from 'layouts';
import AuthorizationPage from 'pages/AuthorizationPage/AuthorizationPage';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    element: <AuthorizationLayout />,
    children: [
      {
        path: UnauthorizedRoutes.SIGN_IN,
        element: <AuthorizationPage />,
        index: true,
      },
      {
        path: UnauthorizedRoutes.SIGN_UP,
        element: <AuthorizationPage />,
      },
      {
        path: UnauthorizedRoutes.VERIFICATION,
        element: <AuthorizationPage />,
      },
      {
        path: UnauthorizedRoutes.REQUEST_VERIFICATION_LINK,
        element: <AuthorizationPage />,
      },
      { path: '*', element: <Navigate to={UnauthorizedRoutes.SIGN_IN} /> },
    ],
  },
]);

const UnauthorizedRouting = () => {
  return <RouterProvider router={router} />;
};

export default UnauthorizedRouting;

import { Upload } from 'api/chat.api';
import { Button, LoadingSpinner, Text } from 'components/atoms';
import { selectSummary, selectUploads } from 'features/chat/selector';
import { getAllSummaryAsync, getSummaryAsync, getUploadsAsync } from 'features/chat/thunks';
import ChevronIcon from 'icons/Chevron';
import PlusIcon from 'icons/Plus';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

const SummaryBar: React.FC = () => {
  const { t } = useTranslation(['gpt']);
  const dispatch = useAppDispatch();
  const summaries = useSelector(selectSummary);
  const currentUploads = useSelector(selectUploads);

  const [loading, setLoading] = useState(false);
  const [uploads, setUploads] = useState<Upload[]>([]);
  const [expandedSummaries, setExpandedSummaries] = useState<{ [key: string]: boolean }>({});
  const [openDropdown, setOpenDropdown] = useState(false);

  const dropdownRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenDropdown(false);
      }
    };

    if (openDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdown]);

  const handleGetSummaryClick = async (upload_id: string) => {
    if (upload_id) {
      setLoading(true);
      try {
        setOpenDropdown(false);
        await dispatch(getSummaryAsync(upload_id));
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleSummary = (id: string) => {
    setExpandedSummaries((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    dispatch(getAllSummaryAsync());
  }, [dispatch, loading]);

  useEffect(() => {
    if (currentUploads) {
      setUploads(currentUploads);
    }
  }, [currentUploads]);

  useEffect(() => {
    dispatch(getUploadsAsync());
  }, [dispatch]);

  return (
    <SummaryContainer>
      <ButtonWrapper>
        {openDropdown && (
          <DropdownMenu ref={dropdownRef}>
            {uploads.map((upload) => (
              <DropdownMenuItem key={upload.upload_id} onClick={() => handleGetSummaryClick(upload.upload_id)}>
                <Text type="body2normal" weight="bold">
                  {upload.filename.slice(0, 10)}
                </Text>
              </DropdownMenuItem>
            ))}
          </DropdownMenu>
        )}
        <Button
          onClickAction={() => setOpenDropdown(true)}
          disabled={uploads.length == 0}
          buttonType="transparent-grey"
          icon={<PlusIcon />}
          text={t('getSummary')}
          textColor="black"
          textType={'label2'}
          padding="var(--Radius-Sharp, 0px) var(--Spacing-ml, 20px)"
          size={'small'}
          weight="bold"
          width="100%"
        />
      </ButtonWrapper>
      <Text type={'label1'} weight="bold">
        {t('summaryPanel')}
      </Text>
      {loading && (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      )}
      <SummaryList>
        {summaries.map((text) => (
          <Summary key={text.upload_id}>
            <FileName onClick={() => toggleSummary(text.upload_id)}>
              <Text type={'caption1'} weight="bold">
                {text.file_name}
              </Text>
              <ChevronIconWrapper isExpanded={expandedSummaries[text.upload_id]}>
                <ChevronIcon />
              </ChevronIconWrapper>
            </FileName>
            {expandedSummaries[text.upload_id] && (
              <TextContainer>
                <Text type={'label1normal'}>{text.summary}</Text>
              </TextContainer>
            )}
          </Summary>
        ))}
      </SummaryList>
    </SummaryContainer>
  );
};

const SummaryContainer = styled.div`
  position: fixed;
  padding: 10px;
  top: 80px;
  right: 0;
  height: calc(100vh - 80px);
  width: 15%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  z-index: 100;
  border-left: 1px solid ${(props) => props.theme.colors.shadeGrey};
  overflow-y: auto;
  flex-shrink: 0;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

const SummaryList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  flex-grow: 1;
  overflow-y: auto;
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

const FileName = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ChevronIconWrapper = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding-top: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 100%;
  padding-bottom: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const DropdownMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: var(--Radius-m, 12px);
  background: white;
  position: fixed;
  right: 300px;
  top: 90px;
  width: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
`;

const DropdownMenuItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  align-items: center;
  margin: 8px;
  gap: 5px;

  &:hover {
    background-color: ${(props) => props.theme.colors.shadeGrey};
  }
`;

export default SummaryBar;

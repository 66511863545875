import { AxiosResponse } from 'axios';
import { sidHttp } from 'services/http';
import { ResponseUnionType } from 'types';

export type GetAllSpeakersResDto = Array<{
  name: string;
}>;

export type GetSpeakerModelResDto = {
  audioFileName: string;
};

export const SpeakersApi = {
  getAllSpeakers: async (): Promise<AxiosResponse<ResponseUnionType<GetAllSpeakersResDto>>> => {
    return sidHttp.get('speaker');
  },

  getSpeakerModel: async (speakerModel: string): Promise<AxiosResponse<ResponseUnionType<GetSpeakerModelResDto>>> => {
    return sidHttp.get(`speaker/audio-file/${speakerModel}`);
  },

  createNewSpeakerModel: async (name: string, formData: FormData): Promise<AxiosResponse<ResponseUnionType<void>>> => {
    return sidHttp.post(`speaker/upload?name=${name}`, formData, {
      headers: {
        type: 'formData',
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteSpeaker: async (name: string) => {
    return sidHttp.delete(`speaker/${name}`);
  },
};

import { selectIsAuthorized, selectIsVerified } from 'features/auth/selectors';
import { useSelector } from 'react-redux';

import AuthorizedRouting from './AuthorizedRouting';
import UnauthorizedRouting from './UnauthorizedRouting';

const AppRouting = () => {
  const isAuthorized = useSelector(selectIsAuthorized);
  const isVerified = useSelector(selectIsVerified);

  const currentLocation = window.location;

  window.localStorage.setItem('previousPath', currentLocation.pathname);

  if (isAuthorized && isVerified) {
    return <AuthorizedRouting />;
  }
  return <UnauthorizedRouting />;
};

export default AppRouting;

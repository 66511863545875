import React from 'react';

const ChevronIcon: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99999 6.97505C5.86665 6.97505 5.73765 6.95005 5.61299 6.90005C5.48765 6.85005 5.38332 6.78338 5.29999 6.70005L0.699987 2.10005C0.516654 1.91672 0.424988 1.68338 0.424988 1.40005C0.424988 1.11671 0.516654 0.883382 0.699987 0.700048C0.883321 0.516715 1.11665 0.425049 1.39999 0.425049C1.68332 0.425049 1.91665 0.516715 2.09999 0.700048L5.99999 4.60005L9.89999 0.700048C10.0833 0.516715 10.3167 0.425049 10.6 0.425049C10.8833 0.425049 11.1167 0.516715 11.3 0.700048C11.4833 0.883382 11.575 1.11671 11.575 1.40005C11.575 1.68338 11.4833 1.91672 11.3 2.10005L6.69999 6.70005C6.59999 6.80005 6.49165 6.87072 6.37499 6.91205C6.25832 6.95405 6.13332 6.97505 5.99999 6.97505Z"
        fill={color ?? '#0B0D1E'}
      />
    </svg>
  );
};

export default ChevronIcon;

import React from 'react';

const HelpIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.90156C7.52646 3.90156 3.89997 7.52806 3.89997 12.0016C3.89997 16.4751 7.52646 20.1016 12 20.1016C16.4735 20.1016 20.1 16.4751 20.1 12.0016C20.1 7.52806 16.4735 3.90156 12 3.90156ZM2.09998 12.0016C2.09998 6.53394 6.53235 2.10156 12 2.10156C17.4676 2.10156 21.9 6.53394 21.9 12.0016C21.9 17.4692 17.4676 21.9016 12 21.9016C6.53235 21.9016 2.09998 17.4692 2.09998 12.0016Z"
        fill="#2E2F33"
        fillOpacity="0.88"
      />
      <path
        d="M13 16.5016C13 17.0538 12.5523 17.5016 12 17.5016C11.4477 17.5016 11 17.0538 11 16.5016C11 15.9493 11.4477 15.5016 12 15.5016C12.5523 15.5016 13 15.9493 13 16.5016Z"
        fill="#2E2F33"
        fillOpacity="0.88"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8646 6.60409C12.437 6.58271 13.0086 6.69723 13.5249 6.94026C14.0415 7.18341 14.49 7.54941 14.8204 8.01022C15.1513 8.47172 15.3509 9.01104 15.392 9.57604C15.4332 10.1412 15.3134 10.7033 15.0498 11.2057C14.7866 11.7074 14.3914 12.1287 13.9107 12.4351L13.9033 12.4398L13.8958 12.4444C13.577 12.639 13.3203 12.895 13.1408 13.1864C12.9652 13.4713 12.8668 13.7841 12.8479 14.1008V14.3766H11.0479V14.0544L11.049 14.0323C11.0801 13.3987 11.2736 12.7853 11.6083 12.2421C11.941 11.7023 12.4022 11.249 12.9493 10.9133C13.1743 10.7685 13.346 10.579 13.4559 10.3695C13.5664 10.1588 13.6131 9.93106 13.5968 9.70664C13.5805 9.48203 13.5008 9.25882 13.3575 9.05905C13.2138 8.8586 13.0096 8.68712 12.7584 8.56887C12.5069 8.4505 12.2217 8.39201 11.9318 8.40284C11.6419 8.41366 11.3643 8.49315 11.1265 8.62857C10.8889 8.76382 10.703 8.94774 10.5793 9.15537C10.456 9.36225 10.3965 9.58858 10.4001 9.81333L8.60036 9.84241C8.59121 9.27588 8.74251 8.72137 9.03309 8.23382C9.32323 7.74702 9.7402 7.34656 10.2359 7.06435C10.7312 6.78231 11.2922 6.62547 11.8646 6.60409Z"
        fill="#2E2F33"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default HelpIcon;

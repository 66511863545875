import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as tranEn from './files/en';
import * as tranKo from './files/ko';

export const languages = ['en', 'ko'];

export type Languages = (typeof languages)[number]; // 'en' | 'ko'

const resources = {
  en: {
    auth: tranEn.authTranslation,
    stt: tranEn.sttTranslation,
    main: tranEn.mainTranslation,
    gpt: tranEn.gptTranslation,
    sid: tranEn.sidTranslation,
  },
  ko: {
    auth: tranKo.authTranslation,
    stt: tranKo.sttTranslation,
    main: tranKo.mainTranslation,
    gpt: tranKo.gptTranslation,
    sid: tranKo.sidTranslation,
  },
};

const languageSettingInLocalStorage = localStorage.getItem('lng');

export const userLanguage =
  !localStorage.getItem('lng') || (languageSettingInLocalStorage !== 'ko' && languageSettingInLocalStorage !== 'en')
    ? 'ko'
    : languageSettingInLocalStorage;

i18n.use(initReactI18next).init({
  resources,
  lng: userLanguage,
  fallbackLng: 'ko',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import { reducer as AnalyzeReducer } from 'features/analyze';
import { reducer as AuthReducer } from 'features/auth';
import { reducer as ChatReducer } from 'features/chat';
import { reducer as SpeakersReducer } from 'features/speakers';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { AppDispatch } from './types';

const authPersistConfig = {
  storage,
  key: 'auth',
  whitelist: ['accessToken', 'isAuthorized', 'isVerified'],
};

export const reducer = combineReducers({
  auth: persistReducer(authPersistConfig, AuthReducer),
  analyze: AnalyzeReducer,
  chat: ChatReducer,
  speakers: SpeakersReducer,
});

export type State = ReturnType<typeof reducer>;

const rootReducer = (state: State, action: Action): State => {
  const nextState = state as State | undefined;

  return reducer(nextState, action);
};

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export default rootReducer as typeof reducer;

import 'react-toastify/dist/ReactToastify.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import useSetMobileViewport from 'hooks/useSetMobileViewport';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Theme from 'Theme';

import { googleClientId } from './config';
import GlobalStyles from './globalStyle';
import { AppRouting } from './routes';

function App() {
  useSetMobileViewport();

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Theme>
        <GlobalStyles />
        <ToastContainer />
        <AppRouting />
      </Theme>
    </GoogleOAuthProvider>
  );
}

export default App;

import { Button, Text } from 'components/atoms';
import { selectUserChats } from 'features/chat/selector';
import {
  createNewChatSessionAsync,
  deleteSessionChatAsync,
  getChatBySessionIdAsync,
  getUserChatsAsync,
} from 'features/chat/thunks';
import DeleteIcon from 'icons/Delete';
import DotsIcon from 'icons/Dots';
import DownloadIcon from 'icons/Download';
import PlusIcon from 'icons/Plus';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { formatIsoToCustomDate } from 'utils/time';

const Sidebar: React.FC = () => {
  const { t } = useTranslation(['gpt']);
  const dispatch = useAppDispatch();
  const userChats = useSelector(selectUserChats);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenDropdown(null);
      }
    };

    if (openDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdown]);

  useEffect(() => {
    dispatch(getUserChatsAsync());
  }, [dispatch]);

  const handleNewChatClick = () => {
    dispatch(createNewChatSessionAsync());
  };

  const downloadChat = (chat: { conversation: any; sessionId: any }) => {
    const chatContent = JSON.stringify(chat.conversation, null, 2);
    const blob = new Blob([chatContent], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.download = `chat_${chat.sessionId}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleChatClick = (sessionId: string) => {
    dispatch(getChatBySessionIdAsync(sessionId));
  };

  const toggleDropdown = (sessionId: string) => {
    setOpenDropdown(openDropdown === sessionId ? null : sessionId);
  };

  const deleteSessionChat = (sessionId: string) => {
    dispatch(deleteSessionChatAsync(sessionId));
    dispatch(getUserChatsAsync());
  };

  return (
    <SidebarContainer>
      <ButtonWrapper>
        <Button
          onClickAction={handleNewChatClick}
          buttonType="transparent-grey"
          icon={<PlusIcon />}
          text={t('newChat')}
          textColor="black"
          textType={'label2'}
          padding="var(--Radius-Sharp, 0px) var(--Spacing-ml, 20px)"
          size={'small'}
          weight="bold"
          width="100%"
        />
      </ButtonWrapper>
      <ChatsList>
        <Text type={'label1'} weight="bold">
          {t('chatHistory')}
        </Text>
        {userChats.map((chat) => (
          <HistoryBox key={chat.sessionId}>
            <TextContainer onClick={() => handleChatClick(chat.sessionId)}>
              <Text type={'label1reading'} textColor="grey">
                {formatIsoToCustomDate(chat.date)}
              </Text>
              <Text type={'body1reading'}>
                {chat.conversation.length > 0 ? chat.conversation.at(0)?.content.substring(0, 15) : 'Conversation'}
              </Text>
            </TextContainer>
            <Icon onClick={() => toggleDropdown(chat.sessionId)}>
              <DotsIcon />
            </Icon>
            {openDropdown === chat.sessionId && (
              <DropdownMenu ref={dropdownRef}>
                <DropdownMenuItem onClick={() => deleteSessionChat(chat.sessionId)}>
                  <IconContainer>
                    <DeleteIcon />
                  </IconContainer>
                  <Text type="body2normal" weight="bold" hoverColor="blue">
                    {t('delete')}
                  </Text>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => downloadChat(chat)}>
                  <IconContainer>
                    <DownloadIcon />
                  </IconContainer>
                  <Text type="body2normal" weight="bold" hoverColor="blue">
                    {t('download')}
                  </Text>
                </DropdownMenuItem>
              </DropdownMenu>
            )}
          </HistoryBox>
        ))}
      </ChatsList>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  position: fixed;
  padding: 10px;
  top: 80px;
  left: 0;
  height: calc(100vh - 80px);
  width: 15%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  z-index: 100;
  border-right: 1px solid ${(props) => props.theme.colors.shadeGrey};
  overflow-y: auto;
  flex-shrink: 0;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 100%;
  padding-bottom: 20px;
`;

const ChatsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`;

const HistoryBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;
  position: relative;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const DropdownMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: var(--Radius-m, 12px);
  background: white;
  position: fixed;
  left: 300px;
  width: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
`;

const DropdownMenuItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  align-items: center;
  margin: 8px;
  gap: 5px;

  &:hover {
    background-color: ${(props) => props.theme.colors.shadeGrey};
  }
`;

export default Sidebar;

import { LanguageDropdown } from 'components/atoms/LanguageDropdown';
import { Logo } from 'components/atoms/Logo';
import { Text } from 'components/atoms/Text';
import { actions as authAction } from 'features/auth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import LogoutIcon from 'icons/Logout';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

const HeaderComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation(['auth']);
  const location = useLocation();
  const { isMobile } = useWindowDimensions();
  const ref = useRef<HTMLDivElement>(null);

  const clickLogo = () => {
    if (location.pathname === '/') {
      window.scrollTo(0, 0);
    } else {
      navigate('/home');
    }
  };

  const signOutHandler = () => {
    dispatch(authAction.signOut());
  };

  return (
    <Maindiv ref={ref}>
      <MenuWrapper>
        <Logo onClick={clickLogo} marginLeft={'0px'} size={isMobile ? 'small' : 'default'} />
        <RightSection>
          <LanguageDropdown />
          {!location.pathname.includes('auth') && (
            <Logout onClick={signOutHandler}>
              <LogoutIcon />
              <Text type="body1normal" textColor="text" weight="bold">
                {t('logout')}
              </Text>
            </Logout>
          )}
        </RightSection>
      </MenuWrapper>
    </Maindiv>
  );
};

const Maindiv = styled.div`
  width: 100%;
  position: sticky;
  z-index: 101;
  height: 80px;
  background-color: ${(props) => props.theme.colors.white};
  top: 0;
  left: 0;
  padding: 18px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.colors.shadeGrey};

  @media only screen and ${deviceBreakpoints.mobile} {
    height: 80px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;

  @media only screen and ${deviceBreakpoints.mobile} {
    align-items: center;
  }
`;

const RightSection = styled.div`
  display: flex;
  gap: 10px;
`;

const Logout = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.purple};
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-bottom: 0;
  }
`;

export default HeaderComponent;

import React from 'react';

const DeleteIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.39766H15V1.59766H9V3.39766ZM21 6.89805H19.6506L19.6506 19.4278C19.6506 19.6821 19.6506 19.9242 19.634 20.1281C19.6158 20.3505 19.5734 20.6057 19.4435 20.8606C19.2613 21.2181 18.9707 21.5088 18.6132 21.691C18.3582 21.8209 18.1031 21.8633 17.8807 21.8814C17.6767 21.8981 17.4347 21.8981 17.1804 21.8981H6.82083C6.56653 21.8981 6.32445 21.8981 6.1205 21.8814C5.89809 21.8633 5.64298 21.8209 5.38801 21.691C5.0305 21.5088 4.73984 21.2181 4.55768 20.8606C4.42776 20.6057 4.38537 20.3505 4.3672 20.1281C4.35054 19.9242 4.35056 19.6821 4.35059 19.4278L4.35059 6.89805H3V5.09805H21V6.89805ZM6.14976 6.89805H17.8498V19.9975C17.8498 20.0527 17.805 20.0975 17.7498 20.0975H6.24976C6.19453 20.0975 6.14976 20.0527 6.14976 19.9975V6.89805ZM13.1 16.498V10.498H14.9V16.498H13.1ZM9.1 10.498V16.498H10.9V10.498H9.1Z"
        fill="#2E2F33"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default DeleteIcon;

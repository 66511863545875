import { ChangeEvent, InputHTMLAttributes } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import styled from 'styled-components';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  hasError?: boolean;
}

export type TextFieldProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const InputField = <T extends FieldValues>({ name, control, rules, label, ...rest }: TextFieldProps<T>) => {
  const { field, fieldState } = useController<T>({ name, control, rules });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.value);
  };

  return (
    <Wrapper>
      <Label htmlFor={name}>{label}</Label>
      <StyledInput
        id={name}
        value={field.value || ''}
        onChange={handleChange}
        hasError={!!fieldState.error}
        {...rest}
      />
      {fieldState.error && <Error>{fieldState.error.message}</Error>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.purple};
`;

const StyledInput = styled.input<{ hasError: boolean }>`
  padding: 12px;
  border: 2px solid ${({ theme, hasError }) => (hasError ? theme.colors.red : theme.colors.grey)};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.white};
  outline: none;

  &:focus {
    border-color: ${({ theme }) => theme.colors.purple};
  }
`;

const Error = styled.span`
  color: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  margin-top: 4px;
`;

export default InputField;

import React from 'react';

const StopIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <circle cx="22" cy="22" r="21.5" stroke="#E53E3E" />
      <circle cx="22" cy="22" r="18" fill="#F1F1F1" />
      <circle cx="22" cy="22" r="10" fill="#E53E3E" />
      <circle cx="22" cy="22" r="15" fill="#E53E3E" fillOpacity="0.6" />
      <circle cx="22" cy="22" r="16.5" stroke="#E53E3E" />
    </svg>
  );
};

export default StopIcon;

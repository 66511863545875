import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { AuthStore } from './types';

export const selectAuth = (state: RootState): AuthStore => state.auth;
export const selectLoadingStatus = createSelector(selectAuth, (auth) => auth.loadingStatus);
export const selectToken = createSelector(selectAuth, (auth) => auth.accessToken);
export const selectIsAuthorized = createSelector(selectAuth, (auth) => auth.isAuthorized);
export const selectVerificationToken = createSelector(selectAuth, (auth) => auth.verificationToken);
export const selectVerificationTokenIsVerified = createSelector(selectAuth, (auth) => auth.verificationTokenIsVerified);
export const selectIsVerified = createSelector(selectAuth, (auth) => auth.isVerified);
export const selectUserId = createSelector(selectAuth, (auth) => auth.userId);

import React from 'react';

const CopyIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.57034 2.10156C4.31602 2.10154 4.07398 2.10152 3.87001 2.11818C3.64761 2.13635 3.3925 2.17874 3.13752 2.30865C2.78001 2.49081 2.48935 2.78148 2.30719 3.13898C2.17727 3.39396 2.13489 3.64907 2.11671 3.87148C2.10005 4.07544 2.10007 4.31749 2.1001 4.57181V15.4313C2.10007 15.6856 2.10005 15.9277 2.11671 16.1316C2.13489 16.3541 2.17727 16.6092 2.30719 16.8641C2.48935 17.2217 2.78001 17.5123 3.13752 17.6945C3.3925 17.8244 3.64761 17.8668 3.87001 17.8849C4.07398 17.9016 4.31602 17.9016 4.57034 17.9016H15.4299C15.6842 17.9016 15.9262 17.9016 16.1302 17.8849C16.3526 17.8668 16.6077 17.8244 16.8627 17.6945C17.2202 17.5123 17.5109 17.2217 17.693 16.8641C17.8229 16.6092 17.8653 16.3541 17.8835 16.1316C17.9001 15.9277 17.9001 15.6856 17.9001 15.4313V4.5718C17.9001 4.31748 17.9001 4.07544 17.8835 3.87148C17.8653 3.64907 17.8229 3.39396 17.693 3.13898C17.5109 2.78148 17.2202 2.49081 16.8627 2.30865C16.6077 2.17874 16.3526 2.13635 16.1302 2.11818C15.9262 2.10152 15.6842 2.10154 15.4299 2.10156H4.57034ZM3.90009 4.00137C3.90009 3.94614 3.94486 3.90137 4.00009 3.90137H16.0001C16.0553 3.90137 16.1001 3.94614 16.1001 4.00137V16.0014C16.1001 16.0566 16.0553 16.1014 16.0001 16.1014H4.00009C3.94486 16.1014 3.90009 16.0566 3.90009 16.0014V4.00137ZM21.8835 20.132C21.9002 19.9281 21.9001 19.686 21.9001 19.4317L21.9001 9.00195L20.1001 9.00234V20.0023C20.1001 20.0576 20.0554 20.1023 20.0001 20.1023H9.00014L9.00012 21.9019L19.4299 21.902C19.6842 21.902 19.9262 21.902 20.1302 21.8853C20.3526 21.8672 20.6077 21.8248 20.8627 21.6949C21.2202 21.5127 21.5109 21.222 21.693 20.8645C21.8229 20.6096 21.8653 20.3544 21.8835 20.132Z"
        fill="#7A7B7E"
        fillOpacity="0.61"
      />
    </svg>
  );
};

export default CopyIcon;

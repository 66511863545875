import React from 'react';

const RAGSummaryIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M13.5002 9.5H2.50024V11.5H13.5002V9.5Z" fill="black" />
      <path d="M13.5002 5.5H2.50024V7.5H13.5002V5.5Z" fill="black" />
      <path d="M9.50024 13.5H2.50024V15.5H9.50024V13.5Z" fill="black" />
      <path
        d="M20.0902 11.43L15.8402 15.67L13.7202 13.55L12.3102 14.96L15.8402 18.5L21.5002 12.84L20.0902 11.43Z"
        fill="black"
      />
    </svg>
  );
};

export default RAGSummaryIcon;

import { gaMeasurementId } from 'config';
import React from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';

import SideBar from './sections/SideBar';
import VoiceSection from './sections/VoiceSection';

ReactGA.initialize(gaMeasurementId);

const STTPage: React.FC = () => {
  return (
    <Wrapper>
      <SideBar />
      <VoiceSection />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default STTPage;

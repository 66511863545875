import React from 'react';

const DotsIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19.2499 13.75C18.2834 13.75 17.4999 12.9665 17.4999 12C17.4999 11.0335 18.2834 10.25 19.2499 10.25C20.2164 10.25 20.9999 11.0335 20.9999 12C20.9999 12.9665 20.2164 13.75 19.2499 13.75Z"
        fill="#AEB0B6"
      />
      <path
        d="M11.9999 13.75C11.0334 13.75 10.2499 12.9665 10.2499 12C10.2499 11.0335 11.0334 10.25 11.9999 10.25C12.9664 10.25 13.7499 11.0335 13.7499 12C13.7499 12.9665 12.9664 13.75 11.9999 13.75Z"
        fill="#AEB0B6"
      />
      <path
        d="M2.99995 12C2.99995 12.9665 3.78346 13.75 4.74995 13.75C5.71645 13.75 6.49995 12.9665 6.49995 12C6.49995 11.0335 5.71645 10.25 4.74995 10.25C3.78346 10.25 2.99995 11.0335 2.99995 12Z"
        fill="#AEB0B6"
      />
    </svg>
  );
};

export default DotsIcon;

import { Text } from 'components/atoms/Text';
import { UnauthorizedRoutes } from 'constants/routes';
import { actions as authAction } from 'features/auth';
import {
  selectLoadingStatus,
  selectUserId,
  selectVerificationToken,
  selectVerificationTokenIsVerified,
} from 'features/auth/selectors';
import { verifyTokenAsync } from 'features/auth/thunks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { LoadingStatus } from 'types';

const VerificationPage: React.FC = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const verificationToken = useSelector(selectVerificationToken);
  const verificationTokenIsVerified = useSelector(selectVerificationTokenIsVerified);
  const loadingStatus = useSelector(selectLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const userId = useSelector(selectUserId);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get('token');
    dispatch(authAction.setVerificationToken(token));
    if (!token && !userId) {
      navigate(UnauthorizedRoutes.SIGN_IN);
    }
  }, []);

  useEffect(() => {
    if (verificationToken) {
      dispatch(verifyTokenAsync(verificationToken));
    }
  }, [verificationToken]);

  if (isLoading) {
    return <div>Loading</div>;
  }

  return verificationTokenIsVerified && verificationToken ? (
    <>
      <Text type={'label1'} weight="bold">
        {t('thanksForConfirmingEmail')}
      </Text>
      <LinkWrapper>
        <StyledLink to={UnauthorizedRoutes.SIGN_IN}>{t('logInHere')}</StyledLink>
      </LinkWrapper>
    </>
  ) : (
    <div>
      <Text type={'label1'} weight="bold">
        {t('pleaseConfirmEmail')}
      </Text>
      <StyledLink to={UnauthorizedRoutes.REQUEST_VERIFICATION_LINK}>{t('resendEmail')}</StyledLink>
    </div>
  );
};

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 765px) {
    margin-bottom: 52px;
    justify-content: center;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default VerificationPage;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'types';
import { v4 as uuidv4 } from 'uuid';

import {
  addChatToConversationAsync,
  createNewChatSessionAsync,
  getAllSummaryAsync,
  getChatBySessionIdAsync,
  getSummaryAsync,
  getUploadsAsync,
  getUserChatsAsync,
  uploadRAGFiles,
  uploadRAGURL,
} from './thunks';
import { ChatStore } from './types';

const initialState: ChatStore = {
  uploadFiles: [],
  sessionId: uuidv4(),
  loadingStatus: LoadingStatus.idle,
  currentConversation: [],
  url: '',
  userChats: [],
  summaries: [],
  uploads: [],
  rag: false,
  error: null,
};

const chatsSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    uploadFiles: (state, { payload }: PayloadAction<string[]>) => {
      return { ...state, uploadFiles: payload };
    },
    uploadURL: (state, { payload }: PayloadAction<string>) => {
      return { ...state, url: payload };
    },
    updateRag: (state, { payload }: PayloadAction<boolean>) => {
      return { ...state, rag: payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserChatsAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getUserChatsAsync.fulfilled, (state, { payload }) => {
      if (payload) {
        state.userChats = [...payload.conversations];
        state.loadingStatus = LoadingStatus.idle;
      }
    });
    builder.addCase(getUserChatsAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(getChatBySessionIdAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getChatBySessionIdAsync.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentConversation = payload.conversation;
        state.sessionId = payload.sessionId;
        state.loadingStatus = LoadingStatus.idle;
      }
    });
    builder.addCase(getChatBySessionIdAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(getUploadsAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getUploadsAsync.fulfilled, (state, { payload }) => {
      if (payload) {
        state.uploads = [...payload.uploads];
        state.loadingStatus = LoadingStatus.idle;
      }
    });
    builder.addCase(getUploadsAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(getAllSummaryAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getAllSummaryAsync.fulfilled, (state, { payload }) => {
      if (payload) {
        state.summaries = payload.summaries;
        state.loadingStatus = LoadingStatus.idle;
      }
    });
    builder.addCase(getAllSummaryAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(getSummaryAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getSummaryAsync.fulfilled, (state, { payload }) => {
      if (payload) {
        state.loadingStatus = LoadingStatus.idle;
      }
    });
    builder.addCase(getSummaryAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(addChatToConversationAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(addChatToConversationAsync.fulfilled, (state, { payload }) => {
      if (payload && payload.conversation) {
        state.currentConversation = [...payload.conversation];
        state.loadingStatus = LoadingStatus.idle;
      }
    });
    builder.addCase(addChatToConversationAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(uploadRAGFiles.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(uploadRAGFiles.fulfilled, (state, { payload }) => {
      return { ...state, payload, loadingStatus: LoadingStatus.idle };
    });
    builder.addCase(uploadRAGFiles.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(uploadRAGURL.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(uploadRAGURL.fulfilled, (state, { payload }) => {
      return { ...state, payload, loadingStatus: LoadingStatus.idle };
    });
    builder.addCase(uploadRAGURL.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(createNewChatSessionAsync.fulfilled, (state, action) => {
      state.currentConversation = [];
      state.sessionId = action.payload;
    });
  },
});

export const actions = {
  ...chatsSlice.actions,
};
export const { reducer } = chatsSlice;

import { googleSignInAsync, signInAsync } from 'features/auth/thunks';
import { AnyAction, Dispatch } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { gptHttp, sidHttp, sttHttp } from 'services/http';

import actions from './actions';

export const authMiddleware =
  () =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction): AnyAction => {
    if (
      action.type === actions.authActions.signIn.type ||
      action.type === actions.authActions.googleLogin.type ||
      action.type === actions.authActions.signUp.type ||
      action.type === signInAsync.fulfilled.type ||
      action.type === googleSignInAsync.fulfilled.type
    ) {
      gptHttp.setAuthorizationHeader(action.payload.data.accessToken);
      sttHttp.setAuthorizationHeader(action.payload.data.accessToken);
      sidHttp.setAuthorizationHeader(action.payload.data.accessToken);
    }

    if (action.type === REHYDRATE && action.payload?.accessToken) {
      gptHttp.setAuthorizationHeader(action.payload?.accessToken);
      sttHttp.setAuthorizationHeader(action.payload?.accessToken);
      sidHttp.setAuthorizationHeader(action.payload?.accessToken);
    }

    return next(action);
  };
